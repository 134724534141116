/* tslint:disable */
/* eslint-disable */
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: { input: string; output: string };
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: { input: number | string; output: number | string };
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: { [key: string]: any }; output: { [key: string]: any } };
  /** The `Upload` scalar type represents a file upload. */
  Upload: { input: File; output: File };
};

/** Possible access levels */
export enum AccessLevels {
  ADMIN = 'ADMIN',
  LIMITED_USER = 'LIMITED_USER',
  READ_ONLY_USER = 'READ_ONLY_USER',
  SUPER_ADMIN = 'SUPER_ADMIN',
}

/** Account source types */
export enum AccountSource {
  APPCLOSE = 'APPCLOSE',
  WAITLIST = 'WAITLIST',
}

export type AccountTokensType = {
  __typename: 'AccountTokensType';
  accessToken?: Maybe<Scalars['String']['output']>;
  isTwoFactorAuthEnable: Scalars['Boolean']['output'];
  refreshToken?: Maybe<Scalars['String']['output']>;
  temporaryToken?: Maybe<Scalars['String']['output']>;
  truncatedPhoneNumber?: Maybe<Scalars['String']['output']>;
};

export type AccountType = {
  __typename: 'AccountType';
  email: Scalars['String']['output'];
  firmSize?: Maybe<FirmSizes>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  validated: Scalars['Boolean']['output'];
};

export type AddressInput = {
  address1: Scalars['String']['input'];
  address2?: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  state: UsaStates;
  type?: InputMaybe<AddressTypes>;
  zipCode: Scalars['String']['input'];
};

export type AddressType = {
  __typename: 'AddressType';
  address1: Scalars['String']['output'];
  address2?: Maybe<Scalars['String']['output']>;
  city: Scalars['String']['output'];
  state: UsaStates;
  type?: Maybe<AddressTypes>;
  zipCode: Scalars['String']['output'];
};

/** Possible address types */
export enum AddressTypes {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
}

export type AgreeScheduledBillInput = {
  invoiceId: Scalars['ID']['input'];
  paymentMethod: PaymentMethodsTypes;
};

export type AppcloseAccount = {
  __typename: 'AppcloseAccount';
  avatar?: Maybe<AssetType>;
  email?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
};

export type AppcloseAccountList = {
  __typename: 'AppcloseAccountList';
  items: Array<AppcloseAccount>;
};

export type AppcloseCalendarEventType = {
  __typename: 'AppcloseCalendarEventType';
  allDay?: Maybe<Scalars['Boolean']['output']>;
  attachments?: Maybe<Array<AssetType>>;
  calendarId: Scalars['String']['output'];
  chatId: Scalars['ID']['output'];
  children?: Maybe<Array<AppcloseChildType>>;
  color?: Maybe<Scalars['String']['output']>;
  from: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  locationName?: Maybe<Scalars['String']['output']>;
  memo?: Maybe<Scalars['String']['output']>;
  reminder1?: Maybe<Scalars['Float']['output']>;
  reminder2?: Maybe<Scalars['Float']['output']>;
  repeatPeriod?: Maybe<Scalars['Float']['output']>;
  repeatUntil?: Maybe<Scalars['String']['output']>;
  shareInfo?: Maybe<AppcloseShareInfoType>;
  sharedLinks?: Maybe<Array<AppcloseSharedLinkType>>;
  title: Scalars['String']['output'];
  to: Scalars['DateTime']['output'];
};

export type AppcloseCalendarGroupType = {
  __typename: 'AppcloseCalendarGroupType';
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type AppcloseCalendarNoteType = {
  __typename: 'AppcloseCalendarNoteType';
  attachment?: Maybe<AssetType>;
  calendarId: Scalars['ID']['output'];
  createdAt: Scalars['DateTime']['output'];
  date: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  text?: Maybe<Scalars['String']['output']>;
};

export type AppcloseCalendarType = {
  __typename: 'AppcloseCalendarType';
  calendarGroupId?: Maybe<Scalars['String']['output']>;
  clientId?: Maybe<Scalars['ID']['output']>;
  color: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isDefault: Scalars['Boolean']['output'];
  shareInfo: AppcloseShareInfoType;
  title: Scalars['String']['output'];
};

export type AppcloseChildType = {
  __typename: 'AppcloseChildType';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type AppcloseExpenseType = {
  __typename: 'AppcloseExpenseType';
  amount: Scalars['Float']['output'];
  attachments?: Maybe<Array<AssetType>>;
  categoryId: Scalars['String']['output'];
  categoryName: Scalars['String']['output'];
  categoryPicture?: Maybe<Scalars['String']['output']>;
  childrenNames?: Maybe<Array<Scalars['String']['output']>>;
  datetime: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  memo?: Maybe<Scalars['String']['output']>;
  paid: Scalars['Boolean']['output'];
  shareInfo?: Maybe<AppcloseShareInfoType>;
  title: Scalars['String']['output'];
};

export type AppcloseInsightsType = {
  __typename: 'AppcloseInsightsType';
  documentInProgressCount: Scalars['Int']['output'];
  newClientRequestsCount: Scalars['Int']['output'];
  newMessagesCount: Scalars['Int']['output'];
  newSharedChatsCount: Scalars['Int']['output'];
  newSharedEventsCount: Scalars['Int']['output'];
  pendingDocumentsToSignCount: Scalars['Int']['output'];
  sharedChats: SharedChatsInsightsType;
  unreadMessages: Array<AppcloseUnreadMessagesInsightType>;
  upcomingEvents: Array<AppcloseUpcomingEventType>;
};

export enum AppclosePaymentType {
  CARD = 'CARD',
  CASH = 'CASH',
  CHECK = 'CHECK',
}

export type AppclosePhoneNumberType = {
  __typename: 'AppclosePhoneNumberType';
  phoneNumber: Scalars['String']['output'];
  phoneNumberCountry: Scalars['String']['output'];
  phoneNumberType: AppclosePhoneTypes;
};

/** Possible phone types */
export enum AppclosePhoneTypes {
  HOME = 'HOME',
  HOME_FAX = 'HOME_FAX',
  MOBILE = 'MOBILE',
  WORK = 'WORK',
  WORK_FAX = 'WORK_FAX',
}

/** Possible Appclose request states */
export enum AppcloseRequestStates {
  APPROVED = 'APPROVED',
  CANCELLED = 'CANCELLED',
  PAID = 'PAID',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  RESUBMITTED = 'RESUBMITTED',
}

export type AppcloseRequestType = {
  __typename: 'AppcloseRequestType';
  amount?: Maybe<Scalars['Float']['output']>;
  attachments?: Maybe<Array<AssetType>>;
  childrenNames?: Maybe<Array<Scalars['String']['output']>>;
  currency?: Maybe<Scalars['String']['output']>;
  from?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  latitude?: Maybe<Scalars['String']['output']>;
  locationName?: Maybe<Scalars['String']['output']>;
  longitude?: Maybe<Scalars['String']['output']>;
  memo?: Maybe<Scalars['String']['output']>;
  percentage?: Maybe<Scalars['Float']['output']>;
  rejectReason?: Maybe<Scalars['String']['output']>;
  shareInfo?: Maybe<AppcloseShareInfoType>;
  state: AppcloseRequestStates;
  title: Scalars['String']['output'];
  to?: Maybe<Scalars['DateTime']['output']>;
  type: AppcloseRequestTypes;
};

/** Possible Appclose request types */
export enum AppcloseRequestTypes {
  DROPOFF = 'DROPOFF',
  OTHER = 'OTHER',
  PICKUP = 'PICKUP',
  REIMBURSEMENT = 'REIMBURSEMENT',
  TRADE = 'TRADE',
}

export enum AppcloseRetainerFeeType {
  NO_RETAINER = 'NO_RETAINER',
  RETAINER_ALWAYS = 'RETAINER_ALWAYS',
  RETAINER_SOMETIMES = 'RETAINER_SOMETIMES',
}

export type AppcloseShareInfoInputType = {
  shareFrom?: InputMaybe<AppcloseShareInfoItemInputType>;
  shareTo?: InputMaybe<Array<AppcloseShareInfoItemInputType>>;
};

export type AppcloseShareInfoItemInputType = {
  id: Scalars['String']['input'];
  shared: Scalars['Boolean']['input'];
  /** contact or email */
  type: ShareInfoItemType;
};

export type AppcloseShareInfoItemType = {
  __typename: 'AppcloseShareInfoItemType';
  avatar?: Maybe<AssetType>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  /** contact or email */
  type?: Maybe<ShareInfoItemType>;
};

export type AppcloseShareInfoType = {
  __typename: 'AppcloseShareInfoType';
  shareFrom?: Maybe<AppcloseShareInfoItemType>;
  shareTo?: Maybe<Array<AppcloseShareInfoItemType>>;
};

export type AppcloseSharedLinkType = {
  __typename: 'AppcloseSharedLinkType';
  email: Scalars['String']['output'];
  link: Scalars['String']['output'];
};

export enum AppcloseSharedObjectType {
  ACTION_REQUEST = 'ACTION_REQUEST',
  CALL = 'CALL',
  EVENT = 'EVENT',
  EXPENSE = 'EXPENSE',
  REQUEST = 'REQUEST',
}

export type AppcloseUnreadMessagesInsightType = {
  __typename: 'AppcloseUnreadMessagesInsightType';
  chatId: Scalars['ID']['output'];
  participants: Array<ChatParticipantType>;
  unreadMessagesCount: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type AppcloseUpcomingEventType = {
  __typename: 'AppcloseUpcomingEventType';
  calendarName: Scalars['String']['output'];
  color: Scalars['String']['output'];
  end: Scalars['DateTime']['output'];
  eventId: Scalars['String']['output'];
  isShared: Scalars['Boolean']['output'];
  start: Scalars['DateTime']['output'];
  title: Scalars['String']['output'];
};

export type ApproveRequestToShareChatInput = {
  coparentId: Scalars['ID']['input'];
  shareForDays: Scalars['Int']['input'];
};

export type ApproveUserAfterReviewInput = {
  email: Scalars['String']['input'];
};

export type AssetThumbnailType = {
  __typename: 'AssetThumbnailType';
  height: Scalars['Float']['output'];
  url: Scalars['String']['output'];
  width: Scalars['Float']['output'];
};

export type AssetType = {
  __typename: 'AssetType';
  filename: Scalars['String']['output'];
  filesize: Scalars['Float']['output'];
  mimetype: Scalars['String']['output'];
  publicId: Scalars['ID']['output'];
  thumbnails: Array<AssetThumbnailType>;
  url: Scalars['String']['output'];
};

export type AttachedEntities = {
  __typename: 'AttachedEntities';
  contact?: Maybe<AttachedToFileEntity>;
  invoice?: Maybe<AttachedToFileEntity>;
  lead?: Maybe<AttachedToFileEntity>;
  payment?: Maybe<AttachedToFileEntity>;
  signature?: Maybe<AttachedToFileEntity>;
  signatureTemplate?: Maybe<AttachedToFileEntity>;
  voidInvoice?: Maybe<AttachedToFileEntity>;
};

export type AttachedToFileEntity = {
  __typename: 'AttachedToFileEntity';
  id: Scalars['ID']['output'];
  type?: Maybe<AttachedToFileEntityType>;
};

/** Types of expenses and payments in entities attached to file */
export enum AttachedToFileEntityType {
  CREDIT_MEMO = 'CREDIT_MEMO',
  FLAT_FEE = 'FLAT_FEE',
  PAYMENT = 'PAYMENT',
  REFUND = 'REFUND',
  SUB_EXPENSE = 'SUB_EXPENSE',
  TIME_ENTRY = 'TIME_ENTRY',
}

export type BackOfficeExportRequestsInsightsType = {
  __typename: 'BackOfficeExportRequestsInsightsType';
  approved: Scalars['Int']['output'];
  pending: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type BackOfficeListUsersInput = {
  filter: FilterUsersInput;
  order: OrderUsersInput;
};

export type BackOfficeSharedChatsInsightsType = {
  __typename: 'BackOfficeSharedChatsInsightsType';
  active: Scalars['Int']['output'];
  pending: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type BackOfficeUsersInsightsType = {
  __typename: 'BackOfficeUsersInsightsType';
  appclose: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
  waitlist: Scalars['Int']['output'];
};

/** Classes of bank accounts */
export enum BankAccountClasses {
  OPERATING = 'OPERATING',
  TRUST = 'TRUST',
}

export type BankAccountInput = {
  accountNumber: Scalars['String']['input'];
  holderName: Scalars['String']['input'];
  routingNumber: Scalars['String']['input'];
  type: BankAccountTypes;
};

export type BankAccountType = {
  __typename: 'BankAccountType';
  accountNumberLast4: Scalars['String']['output'];
  class: BankAccountClasses;
  /** Only for TRUST accounts */
  feeBankAccount?: Maybe<FeeBankAccount>;
  holderName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  routingNumber: Scalars['String']['output'];
  stripeBankAccountStatus: StripeBankAccountStatus;
  type: BankAccountTypes;
};

/** Types of bank accounts */
export enum BankAccountTypes {
  BUSINESS = 'BUSINESS',
  PERSONAL = 'PERSONAL',
}

/** Possible types of banners */
export enum BannerTypes {
  ADD_CONTACTS = 'ADD_CONTACTS',
  ADD_TEAM_MEMBERS = 'ADD_TEAM_MEMBERS',
  LINK_TRUST_ACCOUNT = 'LINK_TRUST_ACCOUNT',
}

export type BillType = {
  __typename: 'BillType';
  accounts?: Maybe<Array<BankAccountType>>;
  balanceDue: Scalars['Float']['output'];
  contact: ContactType;
  createdAt: Scalars['DateTime']['output'];
  destinationAccount?: Maybe<BankAccountClasses>;
  discount: Scalars['Float']['output'];
  dueDate: Scalars['DateTime']['output'];
  firm: PublicFirmType;
  invoiceNumber?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  originalAmount: Scalars['Float']['output'];
  paidAmount: Scalars['Float']['output'];
  paymentAmount?: Maybe<Scalars['Float']['output']>;
  paymentDate?: Maybe<Scalars['Date']['output']>;
  paymentId?: Maybe<Scalars['ID']['output']>;
  paymentMethod?: Maybe<PaymentMethodsTypes>;
  percentageDiscount: Scalars['Boolean']['output'];
  requestedAmount: Scalars['Float']['output'];
  scheduledPayments?: Maybe<ScheduledPaymentsType>;
  source?: Maybe<PaymentSourceType>;
  status: InvoiceStatusesTypes;
  truncatedPAN?: Maybe<Scalars['String']['output']>;
};

/** Possible entity billing contact types */
export enum BillingContactTypes {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
}

export type BlockedEmailType = {
  __typename: 'BlockedEmailType';
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
};

export type Breadcrumb = {
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type BreadcrumbType = {
  __typename: 'BreadcrumbType';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type CalculateScheduledPaymentsInput = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  settings: ScheduledPaymentsSettingsInput;
};

export type CancelInvoiceScheduleInput = {
  id: Scalars['ID']['input'];
};

export type ChangePasswordInput = {
  newPassword: Scalars['String']['input'];
  oldPassword: Scalars['String']['input'];
};

/** Possible chat message delivery statuses */
export enum ChatMessageDeliveryStatuses {
  SEEN = 'SEEN',
  SENT = 'SENT',
}

export type ChatMessageSenderType = {
  __typename: 'ChatMessageSenderType';
  avatar?: Maybe<AssetType>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type ChatMessageStatusType = {
  __typename: 'ChatMessageStatusType';
  accountId: Scalars['String']['output'];
  at: Scalars['DateTime']['output'];
  status: ChatMessageDeliveryStatuses;
};

export type ChatMessageStatusUpdatedType = {
  __typename: 'ChatMessageStatusUpdatedType';
  chatId: Scalars['String']['output'];
  messageId: Scalars['String']['output'];
  statuses: Array<ChatMessageStatusType>;
};

export type ChatMessageType = {
  __typename: 'ChatMessageType';
  asset?: Maybe<AssetType>;
  chatId: Scalars['String']['output'];
  compositeData?: Maybe<CompositeMessageDataType>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  isSaved: Scalars['Boolean']['output'];
  objectMetadata?: Maybe<MessageObjectMetadataType>;
  replyToMessage?: Maybe<ChatMessageType>;
  savedAt?: Maybe<Scalars['DateTime']['output']>;
  sender: ChatMessageSenderType;
  statuses: Array<ChatMessageStatusType>;
  text?: Maybe<Scalars['String']['output']>;
  type: ChatMessageTypes;
  url?: Maybe<Scalars['String']['output']>;
};

/** Possible chat message types */
export enum ChatMessageTypes {
  ATTACHMENT = 'ATTACHMENT',
  COMPOSITE = 'COMPOSITE',
  MEDIA = 'MEDIA',
  OBJECT = 'OBJECT',
  SYSTEM_INFO = 'SYSTEM_INFO',
  SYSTEM_NOTICE = 'SYSTEM_NOTICE',
  TEXT = 'TEXT',
}

export type ChatParticipantStatusType = {
  __typename: 'ChatParticipantStatusType';
  id: Scalars['ID']['output'];
  online: Scalars['Boolean']['output'];
  seenAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ChatParticipantType = {
  __typename: 'ChatParticipantType';
  avatar?: Maybe<AssetType>;
  clientId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type ChatType = {
  __typename: 'ChatType';
  firstUnseenMessageId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['String']['output'];
  isReadOnly: Scalars['Boolean']['output'];
  lastInteractionAt: Scalars['DateTime']['output'];
  lastMessage?: Maybe<ChatMessageType>;
  participants: Array<ChatParticipantType>;
  type: ChatTypes;
  unreadCount: Scalars['Float']['output'];
};

/** Possible chat types */
export enum ChatTypes {
  ANNOUNCEMENTS = 'ANNOUNCEMENTS',
  CHAT = 'CHAT',
}

export type CheckFolderNameExistingInput = {
  driveId?: InputMaybe<Scalars['ID']['input']>;
  folderName: Scalars['String']['input'];
};

export type CheckFolderNameExistingType = {
  __typename: 'CheckFolderNameExistingType';
  exists: Scalars['Boolean']['output'];
};

export type ClientAddressInput = {
  address1?: InputMaybe<Scalars['String']['input']>;
  address2?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type ClientAddressType = {
  __typename: 'ClientAddressType';
  address1?: Maybe<Scalars['String']['output']>;
  address2?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

/** Possible client connection request directions */
export enum ClientConnectionRequestDirection {
  INCOMING = 'INCOMING',
  OUTGOING = 'OUTGOING',
}

export type ClientConnectionRequestList = {
  __typename: 'ClientConnectionRequestList';
  items: Array<ClientConnectionRequestType>;
};

/** Possible client connection request states */
export enum ClientConnectionRequestState {
  APPROVED = 'APPROVED',
  CANCELLED = 'CANCELLED',
  DISCONNECTED = 'DISCONNECTED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
}

export type ClientConnectionRequestType = {
  __typename: 'ClientConnectionRequestType';
  account?: Maybe<AppcloseAccount>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  state: ClientConnectionRequestState;
};

export type ClientList = {
  __typename: 'ClientList';
  items: Array<ClientType>;
  total: Scalars['Float']['output'];
};

/** Possible client statuses */
export enum ClientStatus {
  CONNECTED = 'CONNECTED',
  DELETED = 'DELETED',
  DISCONNECTED = 'DISCONNECTED',
}

export type ClientType = {
  __typename: 'ClientType';
  addresses?: Maybe<Array<ClientAddressType>>;
  avatar?: Maybe<AssetType>;
  clientAccountId: Scalars['ID']['output'];
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<GenderTypes>;
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  middleName?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  status: ClientStatus;
};

export type CoParentOptionType = {
  __typename: 'CoParentOptionType';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

/** Possible contact types */
export enum CombinedContactTypes {
  CONTACT = 'CONTACT',
  ENTITY = 'ENTITY',
}

/** Common errors */
export enum CommonAppErrors {
  AUTH_SIGNIN_EMAIL_NOT_ALLOWED = 'AUTH_SIGNIN_EMAIL_NOT_ALLOWED',
  AUTH_SIGNIN_INVALID_ACCOUNT_TYPE = 'AUTH_SIGNIN_INVALID_ACCOUNT_TYPE',
  AUTH_SIGNIN_INVALID_CREDENTIALS = 'AUTH_SIGNIN_INVALID_CREDENTIALS',
  AUTH_SIGNIN_NEED_TO_REVIEW = 'AUTH_SIGNIN_NEED_TO_REVIEW',
  AUTH_SIGNUP_EMAIL_ALREADY_USED = 'AUTH_SIGNUP_EMAIL_ALREADY_USED',
  AUTH_SIGNUP_INVALID_CAPTCHA = 'AUTH_SIGNUP_INVALID_CAPTCHA',
  AUTH_SIGNUP_NOT_ALLOWED_COUNTRY = 'AUTH_SIGNUP_NOT_ALLOWED_COUNTRY',
  AUTH_SIGNUP_NOT_ALLOWED_STATE = 'AUTH_SIGNUP_NOT_ALLOWED_STATE',
  AUTH_TWO_FACTOR_ALREADY_ENABLED = 'AUTH_TWO_FACTOR_ALREADY_ENABLED',
  AUTH_TWO_FACTOR_CODE_INVALID = 'AUTH_TWO_FACTOR_CODE_INVALID',
  AUTH_TWO_FACTOR_OPTION_DISABLE_FOR_DEMO = 'AUTH_TWO_FACTOR_OPTION_DISABLE_FOR_DEMO',
  AUTH_TWO_FACTOR_OPTION_NOT_FOUND = 'AUTH_TWO_FACTOR_OPTION_NOT_FOUND',
  AUTH_TWO_FACTOR_TOKEN_INVALID = 'AUTH_TWO_FACTOR_TOKEN_INVALID',
  AUTH_TWO_FACTOR_TOKEN_NOT_FOUND = 'AUTH_TWO_FACTOR_TOKEN_NOT_FOUND',
  BANK_ACCOUNTS_OPERATING_ALREADY_EXISTS = 'BANK_ACCOUNTS_OPERATING_ALREADY_EXISTS',
  BANK_ACCOUNTS_TRUST_ALREADY_EXISTS = 'BANK_ACCOUNTS_TRUST_ALREADY_EXISTS',
  BANK_ACCOUNT_DUPLICATION = 'BANK_ACCOUNT_DUPLICATION',
  BANK_ACCOUNT_NOT_FOUND = 'BANK_ACCOUNT_NOT_FOUND',
  BANK_ACCOUNT_ONLY_OWNER_CAN_CREATE_OPERATING = 'BANK_ACCOUNT_ONLY_OWNER_CAN_CREATE_OPERATING',
  BANK_ACCOUNT_TOKEN_INVALID = 'BANK_ACCOUNT_TOKEN_INVALID',
  CALENDAR_ITEM_CONTACT_NOT_FOUND = 'CALENDAR_ITEM_CONTACT_NOT_FOUND',
  CALENDAR_ITEM_DATA_MISSED = 'CALENDAR_ITEM_DATA_MISSED',
  CALENDAR_ITEM_DIRECT_CREATION_RESTRICTED = 'CALENDAR_ITEM_DIRECT_CREATION_RESTRICTED',
  CALENDAR_ITEM_INCOMPATIBLE_FIELDS = 'CALENDAR_ITEM_INCOMPATIBLE_FIELDS',
  CALENDAR_ITEM_INVALID_REPEAT = 'CALENDAR_ITEM_INVALID_REPEAT',
  CALENDAR_ITEM_INVALID_TIME_RANGE = 'CALENDAR_ITEM_INVALID_TIME_RANGE',
  CALENDAR_ITEM_NOT_FOUND = 'CALENDAR_ITEM_NOT_FOUND',
  CALENDAR_ITEM_NO_BOTH_REPEATS_AND_INSERTS = 'CALENDAR_ITEM_NO_BOTH_REPEATS_AND_INSERTS',
  CALENDAR_ITEM_REPEAT_BROKEN_ITEM = 'CALENDAR_ITEM_REPEAT_BROKEN_ITEM',
  CALENDAR_ITEM_REPEAT_TILL_DATE_OVERFLOW = 'CALENDAR_ITEM_REPEAT_TILL_DATE_OVERFLOW',
  CALENDAR_ITEM_USER_NOT_FOUND = 'CALENDAR_ITEM_USER_NOT_FOUND',
  CANT_REFUND_FROM_TRUST_ACCOUNT = 'CANT_REFUND_FROM_TRUST_ACCOUNT',
  CHECKS_CANNOT_PRINT_TWICE = 'CHECKS_CANNOT_PRINT_TWICE',
  CHECKS_CANT_CANCEL_PRINT = 'CHECKS_CANT_CANCEL_PRINT',
  CHECKS_INVALID_NUMBER = 'CHECKS_INVALID_NUMBER',
  CHECKS_QBO_NOT_SUPPORTED = 'CHECKS_QBO_NOT_SUPPORTED',
  CHECKS_SETTINGS_MUST_BE_INITIALIZED = 'CHECKS_SETTINGS_MUST_BE_INITIALIZED',
  CLIENTS_ACCOUNT_NOT_FOUND_BY_EMAIL = 'CLIENTS_ACCOUNT_NOT_FOUND_BY_EMAIL',
  CLIENTS_CANT_CONNECT_TO_HIMSELF = 'CLIENTS_CANT_CONNECT_TO_HIMSELF',
  CLIENTS_INVALID_CLIENT_FOR_REQUEST_SHARE = 'CLIENTS_INVALID_CLIENT_FOR_REQUEST_SHARE',
  CLIENTS_REQUEST_ALREADY_APPROVED = 'CLIENTS_REQUEST_ALREADY_APPROVED',
  CLIENTS_REQUEST_ALREADY_DECLINED = 'CLIENTS_REQUEST_ALREADY_DECLINED',
  CLIENTS_REQUEST_NOT_FOUND = 'CLIENTS_REQUEST_NOT_FOUND',
  DISABLED_FOR_CURRENT_ONBOARD_STATUS = 'DISABLED_FOR_CURRENT_ONBOARD_STATUS',
  DISABLED_FOR_DEMO = 'DISABLED_FOR_DEMO',
  DISABLED_UPDATING_INVOICE_FILES = 'DISABLED_UPDATING_INVOICE_FILES',
  EMAIL_NOT_VERIFIED = 'EMAIL_NOT_VERIFIED',
  EMAIL_OR_PHONE_NUMBER_SHOULD_BE_PROVIDED = 'EMAIL_OR_PHONE_NUMBER_SHOULD_BE_PROVIDED',
  EXPENSES_RESTRICT_UPDATE_STATUS = 'EXPENSES_RESTRICT_UPDATE_STATUS',
  EXPENSE_BILLABLE_REQUIRED = 'EXPENSE_BILLABLE_REQUIRED',
  EXPENSE_CANNOT_SET_BILLABLE = 'EXPENSE_CANNOT_SET_BILLABLE',
  EXPENSE_RESTRICTED_TO_REMOVE = 'EXPENSE_RESTRICTED_TO_REMOVE',
  FILES_EXTENSION_REQUIRED = 'FILES_EXTENSION_REQUIRED',
  FILES_NOT_FOUND = 'FILES_NOT_FOUND',
  FILE_STORAGE_DESTINATION_AND_ITEM_SHOULD_BE_DIFFERENT = 'FILE_STORAGE_DESTINATION_AND_ITEM_SHOULD_BE_DIFFERENT',
  FILE_STORAGE_DESTINATION_FOLDER_IS_NOT_UNIQUE = 'FILE_STORAGE_DESTINATION_FOLDER_IS_NOT_UNIQUE',
  FILE_STORAGE_DESTINATION_FOLDER_SHOULD_BE_DEFINED = 'FILE_STORAGE_DESTINATION_FOLDER_SHOULD_BE_DEFINED',
  FILE_STORAGE_DISABLED_FOR_DEMO = 'FILE_STORAGE_DISABLED_FOR_DEMO',
  FILE_STORAGE_FILE_ATTACH_INFO_WRONG = 'FILE_STORAGE_FILE_ATTACH_INFO_WRONG',
  FILE_STORAGE_FILTER_DISABLED = 'FILE_STORAGE_FILTER_DISABLED',
  FILE_STORAGE_FOLDER_NOT_FOUND = 'FILE_STORAGE_FOLDER_NOT_FOUND',
  FILE_STORAGE_PARENT_FOLDER_NOT_FOUND = 'FILE_STORAGE_PARENT_FOLDER_NOT_FOUND',
  FILE_STORAGE_SOMETHING_WRONG_WITH_SYNC_ACCOUNT = 'FILE_STORAGE_SOMETHING_WRONG_WITH_SYNC_ACCOUNT',
  FILE_STORAGE_SYNC_ACCOUNT_INVALID_CREDENTIALS = 'FILE_STORAGE_SYNC_ACCOUNT_INVALID_CREDENTIALS',
  FILE_STORAGE_SYNC_ACCOUNT_INVALID_PERMISSIONS = 'FILE_STORAGE_SYNC_ACCOUNT_INVALID_PERMISSIONS',
  FILE_STORAGE_SYNC_DESTINATION_FOLDER_DELETED = 'FILE_STORAGE_SYNC_DESTINATION_FOLDER_DELETED',
  FILE_STORAGE_SYSTEM_FOLDER_UPDATE_PROHIBITED = 'FILE_STORAGE_SYSTEM_FOLDER_UPDATE_PROHIBITED',
  FIRM_CANT_CHANGE_PRINT_MODE = 'FIRM_CANT_CHANGE_PRINT_MODE',
  FIRM_CANT_SENT_KYB = 'FIRM_CANT_SENT_KYB',
  FIRM_KYB_NOT_COMPLETED = 'FIRM_KYB_NOT_COMPLETED',
  FIRM_KYB_VALIDATION_FAILED = 'FIRM_KYB_VALIDATION_FAILED',
  FIRM_ONBOARDING_NOT_COMPLETED = 'FIRM_ONBOARDING_NOT_COMPLETED',
  FIRM_ONLY_OWNER_CAN_HIDE_BANNER = 'FIRM_ONLY_OWNER_CAN_HIDE_BANNER',
  FIRM_PAYMENT_LINK_CONTACT_NOT_FOUND = 'FIRM_PAYMENT_LINK_CONTACT_NOT_FOUND',
  FIRST_UPDATE_PROFILE_SHOULD_CONTAINS_ALL_DATA = 'FIRST_UPDATE_PROFILE_SHOULD_CONTAINS_ALL_DATA',
  GOOGLE_AUTH_REQUIRED = 'GOOGLE_AUTH_REQUIRED',
  GOOGLE_CALENDAR_NOT_FOUND = 'GOOGLE_CALENDAR_NOT_FOUND',
  GOOGLE_NOT_CONFIGURED = 'GOOGLE_NOT_CONFIGURED',
  GOOGLE_UNEXPECTED_API_RESPONSE = 'GOOGLE_UNEXPECTED_API_RESPONSE',
  INSUFFICIENT_FUNDS = 'INSUFFICIENT_FUNDS',
  INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
  INVALID_INVOICE = 'INVALID_INVOICE',
  INVOICES_BANK_ACCOUNT_MUST_BE_ONBOARDED = 'INVOICES_BANK_ACCOUNT_MUST_BE_ONBOARDED',
  INVOICES_CANNOT_SET_CUSTOM_PAYMENT_DATE = 'INVOICES_CANNOT_SET_CUSTOM_PAYMENT_DATE',
  INVOICES_CANT_EDIT_AFTER_SCHEDULED_PLAN_APPROVED = 'INVOICES_CANT_EDIT_AFTER_SCHEDULED_PLAN_APPROVED',
  INVOICES_CANT_EDIT_WITH_TRANSACTIONS = 'INVOICES_CANT_EDIT_WITH_TRANSACTIONS',
  INVOICES_INVALID_PAYMENT_DATE = 'INVOICES_INVALID_PAYMENT_DATE',
  INVOICES_TRUST_ACCOUNTING_MUST_BE_COMPLETED = 'INVOICES_TRUST_ACCOUNTING_MUST_BE_COMPLETED',
  INVOICE_CANNOT_CHANGE_CONTACT_OR_MATTERS = 'INVOICE_CANNOT_CHANGE_CONTACT_OR_MATTERS',
  LEAD_ARCHIVE_REASON_REQUIRED = 'LEAD_ARCHIVE_REASON_REQUIRED',
  LEAD_CANT_CHANGE_LEAD_SOURCE = 'LEAD_CANT_CHANGE_LEAD_SOURCE',
  LEAD_CANT_MOVE_OUT_ARCHIVED = 'LEAD_CANT_MOVE_OUT_ARCHIVED',
  LEAD_MUST_HAVE_SAME_STATUS = 'LEAD_MUST_HAVE_SAME_STATUS',
  LEAD_NOT_FOUND = 'LEAD_NOT_FOUND',
  LEAD_RESTRICTED_TO_BE_EMPTY = 'LEAD_RESTRICTED_TO_BE_EMPTY',
  LEAD_STATUS_CONTACT_ASSOCIATION_CONFLICT = 'LEAD_STATUS_CONTACT_ASSOCIATION_CONFLICT',
  LEAD_STATUS_NOT_FOUND = 'LEAD_STATUS_NOT_FOUND',
  LEAD_STATUS_SYSTEM_EDIT_RESTRICTED = 'LEAD_STATUS_SYSTEM_EDIT_RESTRICTED',
  LEAD_STATUS_TOO_MANY_LEAD_IDS = 'LEAD_STATUS_TOO_MANY_LEAD_IDS',
  MICROSOFT_AUTH_REQUIRED = 'MICROSOFT_AUTH_REQUIRED',
  MICROSOFT_NOT_CONFIGURED = 'MICROSOFT_NOT_CONFIGURED',
  MICROSOFT_OUTLOOK_CALENDAR_NAME_DUPLICATE = 'MICROSOFT_OUTLOOK_CALENDAR_NAME_DUPLICATE',
  MODE_UPGRADING_ALREADY_UPGRADED = 'MODE_UPGRADING_ALREADY_UPGRADED',
  MODE_UPGRADING_AVAILABLE_ONLY_FOR_OWNER = 'MODE_UPGRADING_AVAILABLE_ONLY_FOR_OWNER',
  MODE_UPGRADING_INVALID_ONBOARD_STATUS = 'MODE_UPGRADING_INVALID_ONBOARD_STATUS',
  NOT_ACTIVE_ACCOUNT = 'NOT_ACTIVE_ACCOUNT',
  NOT_ACTIVE_CONTACT = 'NOT_ACTIVE_CONTACT',
  NOT_ALLOWED_EMAIL_DOMAIN = 'NOT_ALLOWED_EMAIL_DOMAIN',
  NOT_ALLOWED_PHONE_NUMBER = 'NOT_ALLOWED_PHONE_NUMBER',
  NOT_FOUND_ERROR = 'NOT_FOUND_ERROR',
  NOT_UNIQUE_INVOICES_BANK_ACCOUNT = 'NOT_UNIQUE_INVOICES_BANK_ACCOUNT',
  NOT_UNIQUE_INVOICES_TARGET = 'NOT_UNIQUE_INVOICES_TARGET',
  OAUTH_SERVICE_ID_INVALID = 'OAUTH_SERVICE_ID_INVALID',
  PAYMENTS_BANK_DECLINED_PAYMENT = 'PAYMENTS_BANK_DECLINED_PAYMENT',
  PAYMENTS_CONTACT_ADDRESS_REQUIRED = 'PAYMENTS_CONTACT_ADDRESS_REQUIRED',
  PAYMENTS_CVV_INVALID = 'PAYMENTS_CVV_INVALID',
  PAYMENTS_DISABLED = 'PAYMENTS_DISABLED',
  PAYMENTS_INSUFFICIENT_FUNDS = 'PAYMENTS_INSUFFICIENT_FUNDS',
  PAYMENTS_INVALID_CARD_NUMBER = 'PAYMENTS_INVALID_CARD_NUMBER',
  PAYMENTS_INVALID_PAYMENT_METHOD = 'PAYMENTS_INVALID_PAYMENT_METHOD',
  PAYMENTS_NOTHING_TO_PAY = 'PAYMENTS_NOTHING_TO_PAY',
  PAYMENTS_REFUND_UNSETTLED_TRANSACTION = 'PAYMENTS_REFUND_UNSETTLED_TRANSACTION',
  PROMO_CODE_EXPIRED = 'PROMO_CODE_EXPIRED',
  PROMO_CODE_NOT_EXISTS = 'PROMO_CODE_NOT_EXISTS',
  QBO_CANT_SWITCH_COMPANY = 'QBO_CANT_SWITCH_COMPANY',
  QBO_COMPANY_ALREADY_CONNECTED = 'QBO_COMPANY_ALREADY_CONNECTED',
  QBO_NOT_CONFIGURED = 'QBO_NOT_CONFIGURED',
  QBO_RESYNC_INVALID_IDS = 'QBO_RESYNC_INVALID_IDS',
  QBO_RESYNC_ONLY_FOR_REFUND_PAYMENT = 'QBO_RESYNC_ONLY_FOR_REFUND_PAYMENT',
  QBO_RESYNC_UPDATE_NOT_SUPPORTED = 'QBO_RESYNC_UPDATE_NOT_SUPPORTED',
  QBO_UNKNOWN_ERROR = 'QBO_UNKNOWN_ERROR',
  RECONCILIATION_NOT_FOUND = 'RECONCILIATION_NOT_FOUND',
  RECONCILIATION_NO_PICKED_TRANSACTIONS = 'RECONCILIATION_NO_PICKED_TRANSACTIONS',
  SCHEDULED_PAYMENTS_INVALID_PLAN = 'SCHEDULED_PAYMENTS_INVALID_PLAN',
  SETUP_TRUST_ACCOUNTING_INVALID_BALANCE = 'SETUP_TRUST_ACCOUNTING_INVALID_BALANCE',
  SETUP_TRUST_ACCOUNTING_INVALID_STEP = 'SETUP_TRUST_ACCOUNTING_INVALID_STEP',
  SETUP_TRUST_ACCOUNTING_NEGATIVE_CLEARED_BALANCE = 'SETUP_TRUST_ACCOUNTING_NEGATIVE_CLEARED_BALANCE',
  SIGNATURE_ALREADY_SIGNED = 'SIGNATURE_ALREADY_SIGNED',
  SIGNATURE_DECLINED = 'SIGNATURE_DECLINED',
  SIGNATURE_EMPTY_OR_ENCRYPTED_FILE = 'SIGNATURE_EMPTY_OR_ENCRYPTED_FILE',
  SIGNATURE_MAX_PAGES_COUNT_EXCEEDED = 'SIGNATURE_MAX_PAGES_COUNT_EXCEEDED',
  SIGNATURE_NOT_FOUND = 'SIGNATURE_NOT_FOUND',
  SIGNATURE_PDF_NOT_UPDATED = 'SIGNATURE_PDF_NOT_UPDATED',
  SIGNERS_NOT_UNIQUE = 'SIGNERS_NOT_UNIQUE',
  SITE_BUILDER_CONFIGURATION_NOT_FOUND = 'SITE_BUILDER_CONFIGURATION_NOT_FOUND',
  SITE_BUILDER_CUSTOM_DOMAIN_ALREADY_CREATED = 'SITE_BUILDER_CUSTOM_DOMAIN_ALREADY_CREATED',
  SITE_BUILDER_CUSTOM_DOMAIN_CNAME_RECORD_NOT_FOUND = 'SITE_BUILDER_CUSTOM_DOMAIN_CNAME_RECORD_NOT_FOUND',
  SITE_BUILDER_CUSTOM_DOMAIN_CONNECTION_FAILED = 'SITE_BUILDER_CUSTOM_DOMAIN_CONNECTION_FAILED',
  SITE_BUILDER_CUSTOM_DOMAIN_INVALID = 'SITE_BUILDER_CUSTOM_DOMAIN_INVALID',
  SITE_BUILDER_DISABLED_FOR_CURRENT_ONBOARD_STATUS = 'SITE_BUILDER_DISABLED_FOR_CURRENT_ONBOARD_STATUS',
  SITE_BUILDER_DISABLED_FOR_DEMO = 'SITE_BUILDER_DISABLED_FOR_DEMO',
  SITE_BUILDER_DOMAIN_NOT_FOUND = 'SITE_BUILDER_DOMAIN_NOT_FOUND',
  SITE_BUILDER_INITIAL_CONFIG_ALREADY_CREATED = 'SITE_BUILDER_INITIAL_CONFIG_ALREADY_CREATED',
  SOMETHING_HAPPENED_IN_APPCLOSE = 'SOMETHING_HAPPENED_IN_APPCLOSE',
  SUB_EXPENSE_AGREED_FLAT_FEE_OVERFLOW = 'SUB_EXPENSE_AGREED_FLAT_FEE_OVERFLOW',
  SUB_EXPENSE_CATEGORY_ALREADY_EXISTS = 'SUB_EXPENSE_CATEGORY_ALREADY_EXISTS',
  SUB_EXPENSE_CATEGORY_CANNOT_USE_NOT_ACTIVE = 'SUB_EXPENSE_CATEGORY_CANNOT_USE_NOT_ACTIVE',
  SUB_EXPENSE_CATEGORY_HAS_RELATIONS = 'SUB_EXPENSE_CATEGORY_HAS_RELATIONS',
  SUB_EXPENSE_CATEGORY_INBUILT_CHANGE_RESTRICTED = 'SUB_EXPENSE_CATEGORY_INBUILT_CHANGE_RESTRICTED',
  SUB_EXPENSE_CATEGORY_NOT_FOUND = 'SUB_EXPENSE_CATEGORY_NOT_FOUND',
  TEAM_MEMBERS_CANT_RESEND_INVITATION = 'TEAM_MEMBERS_CANT_RESEND_INVITATION',
  THIRD_PARTY_PAYEE_ALREADY_EXISTS = 'THIRD_PARTY_PAYEE_ALREADY_EXISTS',
  THIRD_PARTY_PAYEE_DISABLED = 'THIRD_PARTY_PAYEE_DISABLED',
  THIRD_PARTY_PAYEE_IN_USE = 'THIRD_PARTY_PAYEE_IN_USE',
  THIRD_PARTY_PAYEE_NOT_FOUND = 'THIRD_PARTY_PAYEE_NOT_FOUND',
  TIMER_ACTIVITY_ALREADY_EXISTS = 'TIMER_ACTIVITY_ALREADY_EXISTS',
  TIMER_ACTIVITY_CANNOT_USE_NOT_ACTIVE = 'TIMER_ACTIVITY_CANNOT_USE_NOT_ACTIVE',
  TIMER_ACTIVITY_HAS_RELATIONS = 'TIMER_ACTIVITY_HAS_RELATIONS',
  TIMER_ACTIVITY_INBUILT_CHANGE_RESTRICTED = 'TIMER_ACTIVITY_INBUILT_CHANGE_RESTRICTED',
  TIMER_ACTIVITY_NOT_FOUND = 'TIMER_ACTIVITY_NOT_FOUND',
  TRUST_TRANSACTION_CANT_VOID_WITHOUT_CHECK = 'TRUST_TRANSACTION_CANT_VOID_WITHOUT_CHECK',
  TRUST_TRANSACTION_INVALID_DEPOSIT_TYPE = 'TRUST_TRANSACTION_INVALID_DEPOSIT_TYPE',
  TRUST_TRANSACTION_RECONCILIATION_DATE_COVER = 'TRUST_TRANSACTION_RECONCILIATION_DATE_COVER',
  TRUST_TRANSACTION_TRANSACTION_COUNT_OVERFLOW = 'TRUST_TRANSACTION_TRANSACTION_COUNT_OVERFLOW',
  TRUST_TRANSFER_INVALID_CONTACT = 'TRUST_TRANSFER_INVALID_CONTACT',
  UNIQUE_VIOLATION = 'UNIQUE_VIOLATION',
  USER_NOT_FOUND = 'USER_NOT_FOUND',
  USER_PRIMARY_NUMBERS_OVERFLOW = 'USER_PRIMARY_NUMBERS_OVERFLOW',
}

export type CommonAppErrorsType = {
  __typename: 'CommonAppErrorsType';
  example: CommonAppErrors;
};

export type CommonDateTimeFilterInput = {
  from?: InputMaybe<Scalars['DateTime']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CommonPaymentStatFilterInput = {
  from?: InputMaybe<Scalars['DateTime']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CommonPaymentStatType = {
  __typename: 'CommonPaymentStatType';
  dueToOperation: Scalars['Float']['output'];
  dueToTrust: Scalars['Float']['output'];
  otoAmount: Scalars['Float']['output'];
  paidToOperation: Scalars['Float']['output'];
  trustBalance: Scalars['Float']['output'];
};

export type CompanyInfo = {
  __typename: 'CompanyInfo';
  additionalAddress?: Maybe<Scalars['String']['output']>;
  additionalPhoneNumbers?: Maybe<Array<AppclosePhoneNumberType>>;
  address: Scalars['String']['output'];
  city: Scalars['String']['output'];
  companyName: Scalars['String']['output'];
  country: Scalars['String']['output'];
  email?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  phoneNumberCountry?: Maybe<Scalars['String']['output']>;
  phoneNumberType?: Maybe<AppclosePhoneTypes>;
  state?: Maybe<Scalars['String']['output']>;
  website?: Maybe<Scalars['String']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type CompositeMessageButtonType = {
  __typename: 'CompositeMessageButtonType';
  content: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type CompositeMessageDataType = {
  __typename: 'CompositeMessageDataType';
  assetUrl?: Maybe<Scalars['String']['output']>;
  buttonBlock?: Maybe<CompositeMessageButtonType>;
  htmlBlock?: Maybe<Scalars['String']['output']>;
  subject: Scalars['String']['output'];
};

export type ContactPaymentStatFilterInput = {
  bankAccountClass?: InputMaybe<BankAccountClasses>;
  contactId?: InputMaybe<Scalars['ID']['input']>;
  from?: InputMaybe<Scalars['DateTime']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ContactPaymentStatType = {
  __typename: 'ContactPaymentStatType';
  due: Scalars['Float']['output'];
  overdue: Scalars['Float']['output'];
  paid: Scalars['Float']['output'];
};

export type ContactRevenueType = {
  __typename: 'ContactRevenueType';
  balanceDue: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  isEntity: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  paidAmount: Scalars['Float']['output'];
  revenueAbsolute: Scalars['Float']['output'];
  revenuePercent: Scalars['Float']['output'];
};

export type ContactStatType = {
  __typename: 'ContactStatType';
  addresses?: Maybe<Array<AddressType>>;
  amountRate: Scalars['Float']['output'];
  balanceDue: Scalars['Float']['output'];
  billingContacts?: Maybe<Array<EntityBillingContactsType>>;
  conflictsCheckCompleted: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  date: Scalars['DateTime']['output'];
  email?: Maybe<Scalars['String']['output']>;
  engagementHasBeenSigned: Scalars['Boolean']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<GenderTypes>;
  id: Scalars['ID']['output'];
  isEntity: Scalars['Boolean']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  middleName?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  originalAmount: Scalars['Float']['output'];
  originatingAttorney?: Maybe<UserType>;
  paidAmount: Scalars['Float']['output'];
  phoneNumbers?: Maybe<Array<PhoneNumberType>>;
  qboSyncState?: Maybe<QboEntitySyncStatus>;
  requestedAmount: Scalars['Float']['output'];
  status: ContactStatuses;
  taxable: Scalars['Boolean']['output'];
  totalInvoices: Scalars['Float']['output'];
  updatedAt: Scalars['DateTime']['output'];
  w9FormSentToClient: Scalars['Boolean']['output'];
  website?: Maybe<Scalars['String']['output']>;
};

/** Possible contact statuses */
export enum ContactStatuses {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
  DELETED = 'DELETED',
}

export type ContactSubscriptionInput = {
  combinedContactType?: InputMaybe<CombinedContactTypes>;
  from?: InputMaybe<Scalars['DateTime']['input']>;
  includeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  statuses?: InputMaybe<Array<ContactStatuses>>;
  to?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ContactType = {
  __typename: 'ContactType';
  addresses?: Maybe<Array<AddressType>>;
  billingContacts?: Maybe<Array<EntityBillingContactsType>>;
  conflictsCheckCompleted: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  email?: Maybe<Scalars['String']['output']>;
  engagementHasBeenSigned: Scalars['Boolean']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<GenderTypes>;
  id: Scalars['ID']['output'];
  isEntity: Scalars['Boolean']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  middleName?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  originatingAttorney?: Maybe<UserType>;
  phoneNumbers?: Maybe<Array<PhoneNumberType>>;
  qboSyncState?: Maybe<QboEntitySyncStatus>;
  status: ContactStatuses;
  taxable: Scalars['Boolean']['output'];
  updatedAt: Scalars['DateTime']['output'];
  w9FormSentToClient: Scalars['Boolean']['output'];
  website?: Maybe<Scalars['String']['output']>;
};

export type ContactsImportResultType = {
  __typename: 'ContactsImportResultType';
  added: Scalars['Int']['output'];
  duplicated: Scalars['Int']['output'];
  ignored: Scalars['Int']['output'];
  invalid: Scalars['Int']['output'];
  processed: Scalars['Int']['output'];
};

export type CopyFileInput = {
  entityId?: InputMaybe<Scalars['ID']['input']>;
  entityType?: InputMaybe<S3FileSources>;
  fileName?: InputMaybe<Scalars['String']['input']>;
  folderId?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
};

export type CreateAppcloseCalendarEventInput = {
  allDay?: InputMaybe<Scalars['Boolean']['input']>;
  attachmentIds?: InputMaybe<Array<Scalars['String']['input']>>;
  calendarId: Scalars['String']['input'];
  from: Scalars['DateTime']['input'];
  locationName?: InputMaybe<Scalars['String']['input']>;
  memo?: InputMaybe<Scalars['String']['input']>;
  reminder1?: InputMaybe<Scalars['Float']['input']>;
  reminder2?: InputMaybe<Scalars['Float']['input']>;
  repeatPeriod?: InputMaybe<Scalars['Float']['input']>;
  repeatUntil?: InputMaybe<Scalars['String']['input']>;
  shareInfo?: InputMaybe<AppcloseShareInfoInputType>;
  title: Scalars['String']['input'];
  to: Scalars['DateTime']['input'];
};

export type CreateAppcloseCalendarInput = {
  calendarGroupId?: InputMaybe<Scalars['String']['input']>;
  color: Scalars['String']['input'];
  shareInfo?: InputMaybe<AppcloseShareInfoInputType>;
  title: Scalars['String']['input'];
};

export type CreateAppcloseNoteInput = {
  attachmentId?: InputMaybe<Scalars['String']['input']>;
  calendarId: Scalars['ID']['input'];
  date: Scalars['Date']['input'];
  text?: InputMaybe<Scalars['String']['input']>;
};

export type CreateChatMessageInput = {
  /** public id of uploaded to CDN file */
  attachmentId?: InputMaybe<Scalars['String']['input']>;
  chatId: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  replyToMessageId?: InputMaybe<Scalars['String']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
};

export type CreateContactDataInput = {
  addresses?: InputMaybe<Array<AddressInput>>;
  conflictsCheckCompleted: Scalars['Boolean']['input'];
  email: Scalars['String']['input'];
  engagementHasBeenSigned: Scalars['Boolean']['input'];
  firstName: Scalars['String']['input'];
  gender?: InputMaybe<GenderTypes>;
  lastName: Scalars['String']['input'];
  middleName?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  originatingAttorney: Scalars['ID']['input'];
  phoneNumbers?: InputMaybe<Array<PhoneNumberInput>>;
  taxable: Scalars['Boolean']['input'];
  w9FormSentToClient: Scalars['Boolean']['input'];
};

export type CreateContactInput = {
  contact?: InputMaybe<CreateContactDataInput>;
  entity?: InputMaybe<CreateEntityDataInput>;
};

export type CreateEntityDataInput = {
  addresses?: InputMaybe<Array<AddressInput>>;
  billingContacts: Array<EntityBillingContactsInput>;
  conflictsCheckCompleted: Scalars['Boolean']['input'];
  engagementHasBeenSigned: Scalars['Boolean']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  originatingAttorney: Scalars['ID']['input'];
  phoneNumbers?: InputMaybe<Array<PhoneNumberInput>>;
  taxable: Scalars['Boolean']['input'];
  w9FormSentToClient: Scalars['Boolean']['input'];
  website?: InputMaybe<Scalars['String']['input']>;
};

export type CreateExportRequestInput = {
  chatId: Scalars['String']['input'];
  from: Scalars['Date']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
  to: Scalars['Date']['input'];
};

export type CreateFilesInput = {
  entityId: Scalars['ID']['input'];
  files: Array<S3FileInput>;
  folderId?: InputMaybe<Scalars['ID']['input']>;
};

export type CreateFilesWithoutEntityInput = {
  files: Array<S3FileInput>;
  folderId?: InputMaybe<Scalars['ID']['input']>;
};

export type CreateFolderDataInput = {
  name: Scalars['String']['input'];
  parentId?: InputMaybe<Scalars['ID']['input']>;
};

export type CreateInvoiceInput = {
  amount: Scalars['Float']['input'];
  contactId: Scalars['ID']['input'];
  destinationAccount: BankAccountClasses;
  discount: Scalars['Float']['input'];
  dueDate: Scalars['DateTime']['input'];
  expenses?: InputMaybe<Array<Scalars['ID']['input']>>;
  flatFees?: InputMaybe<Array<Scalars['ID']['input']>>;
  internalMemo?: InputMaybe<Scalars['String']['input']>;
  invoiceNumber?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  percentageDiscount: Scalars['Boolean']['input'];
  scheduleSettings?: InputMaybe<ScheduledPaymentsSettingsInput>;
  timeEntries?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type CreatePasswordInput = {
  password: Scalars['String']['input'];
};

export type CreatePaymentInput = {
  amount: Scalars['Float']['input'];
  internalMemo?: InputMaybe<Scalars['String']['input']>;
  invoices: Array<Scalars['ID']['input']>;
  paymentDate?: InputMaybe<Scalars['Date']['input']>;
  paymentMethod: PaymentMethodsTypes;
};

export type CreatePaymentIntentInput = {
  amount: Scalars['Float']['input'];
  internalMemo?: InputMaybe<Scalars['String']['input']>;
  invoiceIds: Array<Scalars['ID']['input']>;
  paymentMethod: PaymentMethodsTypes;
};

export type CreateRefundPaymentInput = {
  amount: Scalars['Float']['input'];
  id: Scalars['ID']['input'];
  internalMemo?: InputMaybe<Scalars['String']['input']>;
};

export type CreateSignatureDraftWithTemplatesInput = {
  files?: InputMaybe<Array<S3FileInput>>;
  nonSignatoryRecipients?: InputMaybe<Array<Scalars['String']['input']>>;
  signers: Array<SignerTemplateInput>;
  templateId: Scalars['ID']['input'];
  text?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
};

export type CreateSignatureDraftWithoutTemplatesInput = {
  completeInOrder: Scalars['Boolean']['input'];
  files: Array<S3FileInput>;
  nonSignatoryRecipients?: InputMaybe<Array<Scalars['String']['input']>>;
  signers: Array<SignerInput>;
  text?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
};

export type CreateSignatureTemplateInput = {
  completeInOrder: Scalars['Boolean']['input'];
  files: Array<S3FileInput>;
  signerRoles: Array<SignerRoleTemplateInput>;
  title: Scalars['String']['input'];
};

export type CreateTeamMemberInput = {
  accessLevel?: InputMaybe<AccessLevels>;
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  hourlyRate?: InputMaybe<Scalars['Float']['input']>;
  lastName: Scalars['String']['input'];
  role: TeamMemberRoles;
};

export type CreateVoidInvoiceInput = {
  id: Scalars['ID']['input'];
  voidInvoiceText?: InputMaybe<Scalars['String']['input']>;
};

export type Credentials = {
  login: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type CreditMemoInput = {
  creditAmount: Scalars['Float']['input'];
  invoiceId: Scalars['ID']['input'];
  memoText?: InputMaybe<Scalars['String']['input']>;
};

/** Types of dashboard analytic */
export enum DashboardAnalyticTypes {
  OUTSTANDING_INVOICES = 'OUTSTANDING_INVOICES',
  PAID_INVOICES = 'PAID_INVOICES',
}

export type DashboardStatContactType = {
  __typename: 'DashboardStatContactType';
  balanceDue?: Maybe<Scalars['Float']['output']>;
  countOfInvoices: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  paidAmount?: Maybe<Scalars['Float']['output']>;
};

export type DashboardStatFilterInput = {
  analyticType: DashboardAnalyticTypes;
  filter?: InputMaybe<CommonDateTimeFilterInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type DashboardStatType = {
  __typename: 'DashboardStatType';
  contacts?: Maybe<Array<DashboardStatContactType>>;
  outstandingAmount?: Maybe<Scalars['Float']['output']>;
  paidAmount?: Maybe<Scalars['Float']['output']>;
};

export type DeclineRequestToClientInput = {
  reason?: InputMaybe<Scalars['String']['input']>;
};

export type DeleteFileInput = {
  id: Scalars['ID']['input'];
};

export type DeleteFilesInput = {
  entityId: Scalars['ID']['input'];
  fileIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type DeleteFolderInput = {
  id: Scalars['ID']['input'];
};

export type DrivesType = {
  __typename: 'DrivesType';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type EmailInput = {
  email: Scalars['String']['input'];
};

export type EntityBillingContactsInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  lastName: Scalars['String']['input'];
  middleName?: InputMaybe<Scalars['String']['input']>;
  type: BillingContactTypes;
};

export type EntityBillingContactsType = {
  __typename: 'EntityBillingContactsType';
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  middleName?: Maybe<Scalars['String']['output']>;
  type: BillingContactTypes;
};

export type ExportChatRequestDetailsType = {
  __typename: 'ExportChatRequestDetailsType';
  coparentName: Scalars['String']['output'];
  from: Scalars['Date']['output'];
  proFirstName: Scalars['String']['output'];
  proLastName: Scalars['String']['output'];
  reason?: Maybe<Scalars['String']['output']>;
  to: Scalars['Date']['output'];
};

export type ExportMessagesInput = {
  chatId: Scalars['String']['input'];
  from: Scalars['DateTime']['input'];
  to: Scalars['DateTime']['input'];
};

export type ExternalSignerInput = {
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type ExternalSignerType = {
  __typename: 'ExternalSignerType';
  email: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type FeeBankAccount = {
  __typename: 'FeeBankAccount';
  truncatedNumber: Scalars['String']['output'];
};

export type FileAttachEntityInput = {
  contactId?: InputMaybe<Scalars['ID']['input']>;
  entityId?: InputMaybe<Scalars['ID']['input']>;
  entityType?: InputMaybe<S3FileSources>;
  fileName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type FileBase64ResponseType = {
  __typename: 'FileBase64ResponseType';
  data: Scalars['String']['output'];
  mimetype: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

/** Possible categories to file storage */
export enum FileCategories {
  DOCUMENTS = 'DOCUMENTS',
  MEDIA = 'MEDIA',
  OTHER = 'OTHER',
}

export type FileDataToUploadInput = {
  id: Scalars['ID']['input'];
  mimetype: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type FileFolderListFilter = {
  category?: InputMaybe<FileCategories>;
  contactIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  fileId?: InputMaybe<Scalars['ID']['input']>;
  fileIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  folderId?: InputMaybe<Scalars['ID']['input']>;
  from?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  invoiceIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  paymentIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
  signatureIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  signatureTemplateIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  source?: InputMaybe<S3FileSources>;
  to?: InputMaybe<Scalars['DateTime']['input']>;
  type?: InputMaybe<FileStorageItemTypes>;
  voidInvoiceIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type FileFolderListInput = {
  contactId?: InputMaybe<Scalars['ID']['input']>;
  filters?: InputMaybe<FileFolderListFilter>;
  leadId?: InputMaybe<Scalars['ID']['input']>;
  order?: InputMaybe<OrderFilesStorageInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type FileStorageItemType = {
  __typename: 'FileStorageItemType';
  attach?: Maybe<AttachedEntities>;
  id: Scalars['ID']['output'];
  isFolder: Scalars['Boolean']['output'];
  isSystemFolder?: Maybe<Scalars['Boolean']['output']>;
  meta?: Maybe<Scalars['String']['output']>;
  mimetype?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  parentId?: Maybe<Scalars['ID']['output']>;
  size?: Maybe<Scalars['Float']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

/** Possible item types in file storage */
export enum FileStorageItemTypes {
  FILES = 'FILES',
  FOLDERS = 'FOLDERS',
}

/** File storage sync error types */
export enum FileStorageSyncErrorTypes {
  ACCOUNT_TROUBLE = 'ACCOUNT_TROUBLE',
  DESTINATION_TROUBLE = 'DESTINATION_TROUBLE',
}

export type FileStorageSyncOptionType = {
  __typename: 'FileStorageSyncOptionType';
  breadcrumbs?: Maybe<Array<BreadcrumbType>>;
  currentDriveErrorCode?: Maybe<CommonAppErrors>;
  currentDriveErrorType?: Maybe<FileStorageSyncErrorTypes>;
  driveId?: Maybe<Scalars['ID']['output']>;
  driveName?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  fileSyncActive?: Maybe<Scalars['Boolean']['output']>;
  folderId?: Maybe<Scalars['ID']['output']>;
  folderName?: Maybe<Scalars['String']['output']>;
  syncType?: Maybe<FileStorageSyncTypes>;
};

/** File storage sync types */
export enum FileStorageSyncTypes {
  TO_EXTERNAL_STORAGE = 'TO_EXTERNAL_STORAGE',
  TWO_WAY_SYNC = 'TWO_WAY_SYNC',
}

export type FileSubscriptionInput = {
  contactIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  from?: InputMaybe<Scalars['DateTime']['input']>;
  includeFilesIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  invoiceIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  paymentIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  signatureIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  signatureTemplateIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  to?: InputMaybe<Scalars['DateTime']['input']>;
  voidInvoiceIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type FileUrlResponseType = {
  __typename: 'FileURLResponseType';
  url: Scalars['String']['output'];
};

export type FileUploadUrlType = {
  __typename: 'FileUploadUrlType';
  id: Scalars['ID']['output'];
  mimetype: Scalars['String']['output'];
  name: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type FileUploadingUrlsType = {
  __typename: 'FileUploadingUrlsType';
  files: Array<FileUploadUrlType>;
};

export type FilterBlockedEmailsInput = {
  search?: InputMaybe<Scalars['String']['input']>;
};

export type FilterContactInput = {
  combinedContactType?: InputMaybe<CombinedContactTypes>;
  from?: InputMaybe<Scalars['DateTime']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<ContactStatuses>>;
  to?: InputMaybe<Scalars['DateTime']['input']>;
};

export type FilterFilesInput = {
  entityIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  from?: InputMaybe<Scalars['DateTime']['input']>;
  mimeTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
  strictFilter?: InputMaybe<Scalars['Boolean']['input']>;
  to?: InputMaybe<Scalars['DateTime']['input']>;
};

export type FilterPaymentInput = {
  bankAccountClass?: InputMaybe<BankAccountClasses>;
  contactId?: InputMaybe<Scalars['ID']['input']>;
  from?: InputMaybe<Scalars['DateTime']['input']>;
  paymentMethod?: InputMaybe<PaymentMethodsTypes>;
  search?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<PaymentStatuses>>;
  to?: InputMaybe<Scalars['DateTime']['input']>;
  types?: InputMaybe<Array<TransactionTypes>>;
};

export type FilterRevenueStatInput = {
  contactType?: InputMaybe<CombinedContactTypes>;
  from?: InputMaybe<Scalars['DateTime']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Scalars['DateTime']['input']>;
};

export type FilterTeamMemberInput = {
  accessLevels?: InputMaybe<Array<AccessLevels>>;
  exclude?: InputMaybe<Array<Scalars['ID']['input']>>;
  hasLeads?: InputMaybe<Scalars['Boolean']['input']>;
  roles?: InputMaybe<Array<TeamMemberRoles>>;
  search?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<TeamMemberStatuses>>;
};

export type FilterUsersInput = {
  source?: InputMaybe<AccountSource>;
};

export type FinancialTilesUpdatedType = {
  __typename: 'FinancialTilesUpdatedType';
  firmId: Scalars['ID']['output'];
};

/** Possible entity types of firms */
export enum FirmEntityTypes {
  C_CORP = 'C_CORP',
  GOVERNMENT_AGENCY = 'GOVERNMENT_AGENCY',
  LLC = 'LLC',
  NOT_FOR_PROFIT = 'NOT_FOR_PROFIT',
  PARTNERSHIP = 'PARTNERSHIP',
  SOLE_PROP = 'SOLE_PROP',
  S_CORP = 'S_CORP',
}

/** Firm size values */
export enum FirmSizes {
  NOT_A_LAW_FIRM = 'NOT_A_LAW_FIRM',
  S1 = 'S1',
  S2_4 = 'S2_4',
  S5_9 = 'S5_9',
  S10_19 = 'S10_19',
  S20_99 = 'S20_99',
  S100_500 = 'S100_500',
  S501_PLUS = 'S501_PLUS',
}

export type FirmType = {
  __typename: 'FirmType';
  address?: Maybe<AddressType>;
  banners: Array<BannerTypes>;
  businessStartedSince?: Maybe<Scalars['Date']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  entityType?: Maybe<FirmEntityTypes>;
  id: Scalars['ID']['output'];
  legalName?: Maybe<Scalars['String']['output']>;
  logo?: Maybe<PictureType>;
  name: Scalars['String']['output'];
  paymentLink?: Maybe<Scalars['String']['output']>;
  paymentsEnabled: Scalars['Boolean']['output'];
  phoneNumber?: Maybe<PhoneNumberType>;
  principal?: Maybe<PrincipalType>;
  size?: Maybe<FirmSizes>;
  stripeKybErrors?: Maybe<Array<Scalars['String']['output']>>;
  stripeKybStatus?: Maybe<StripeKybStatus>;
  /**
   * example
   *  full:America/Chicago
   *  short: CDT
   */
  timezone?: Maybe<TimezoneType>;
  website?: Maybe<Scalars['String']['output']>;
};

export type FolderPathType = {
  __typename: 'FolderPathType';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  parentId?: Maybe<Scalars['ID']['output']>;
};

export type FoldersInDriveType = {
  __typename: 'FoldersInDriveType';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

/** Possible gender types */
export enum GenderTypes {
  FEMALE = 'FEMALE',
  MALE = 'MALE',
  UNDEFINED = 'UNDEFINED',
}

export type GenerateLinkInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type GetDriveFoldersResponseType = {
  __typename: 'GetDriveFoldersResponseType';
  folders: Array<FoldersInDriveType>;
  nextPageToken?: Maybe<Scalars['String']['output']>;
  parentId?: Maybe<Scalars['ID']['output']>;
};

export type GetDrivesResponseType = {
  __typename: 'GetDrivesResponseType';
  drives: Array<DrivesType>;
  nextPageToken?: Maybe<Scalars['String']['output']>;
};

export type GetFileOfFolderInput = {
  id: Scalars['ID']['input'];
};

export type GetFolderInput = {
  contactId?: InputMaybe<Scalars['ID']['input']>;
};

export type GetGoogleDriveFoldersInput = {
  driveId?: InputMaybe<Scalars['ID']['input']>;
  nextPageToken?: InputMaybe<Scalars['String']['input']>;
  parentId?: InputMaybe<Scalars['ID']['input']>;
};

export type GetGoogleDrivesInput = {
  nextPageToken?: InputMaybe<Scalars['String']['input']>;
};

export type GetPathInput = {
  id: Scalars['ID']['input'];
};

export type GetQboRedirectUrlInput = {
  redirectRoute: Scalars['String']['input'];
};

export type GetQboRedirectUrlResponse = {
  __typename: 'GetQboRedirectUrlResponse';
  url: Scalars['String']['output'];
};

export type GetSignatureTemplateInput = {
  id: Scalars['ID']['input'];
};

export type GetSignedUrlForUploadingFilesInput = {
  filesToUpload: Array<FileDataToUploadInput>;
};

export type GoogleAuthUrlType = {
  __typename: 'GoogleAuthUrlType';
  authUrl: Scalars['String']['output'];
};

export enum GoogleCalendarErrorCodes {
  INSUFFICIENT_CREDENTIALS = 'INSUFFICIENT_CREDENTIALS',
  INVALID_GRANT = 'INVALID_GRANT',
  INVALID_TOKEN = 'INVALID_TOKEN',
}

export type GooglePersonalIntegrationStatusType = {
  __typename: 'GooglePersonalIntegrationStatusType';
  email?: Maybe<Scalars['String']['output']>;
  enabled: Scalars['Boolean']['output'];
  error?: Maybe<GoogleCalendarErrorCodes>;
};

export type HideBannerInput = {
  banner: BannerTypes;
  skip?: InputMaybe<Scalars['Boolean']['input']>;
};

export type IdentifiedEntityType = {
  __typename: 'IdentifiedEntityType';
  id?: Maybe<Scalars['ID']['output']>;
};

export type InputCompanyInfo = {
  additionalAddress?: InputMaybe<Scalars['String']['input']>;
  additionalPhoneNumbers?: InputMaybe<Array<InputPhoneNumber>>;
  address: Scalars['String']['input'];
  city: Scalars['String']['input'];
  companyName: Scalars['String']['input'];
  country: Scalars['String']['input'];
  email: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
  phoneNumberCountry: Scalars['String']['input'];
  phoneNumberType?: InputMaybe<AppclosePhoneTypes>;
  state?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type InputLicense = {
  licenseNumber?: InputMaybe<Scalars['String']['input']>;
  licensedIn?: InputMaybe<Array<Scalars['String']['input']>>;
  licensedSince?: InputMaybe<Scalars['String']['input']>;
  occupation: Scalars['String']['input'];
};

export type InputPersonalInfo = {
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  gender?: InputMaybe<GenderTypes>;
  lastName: Scalars['String']['input'];
  middleName?: InputMaybe<Scalars['String']['input']>;
  phoneNumber: Scalars['String']['input'];
  phoneNumberCountry: Scalars['String']['input'];
  phoneNumberType: AppclosePhoneTypes;
  state?: InputMaybe<Scalars['String']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type InputPhoneNumber = {
  phoneNumber: Scalars['String']['input'];
  phoneNumberCountry: Scalars['String']['input'];
  phoneNumberType: AppclosePhoneTypes;
};

export type InputServicesAndFees = {
  brochuresCount?: InputMaybe<Scalars['Float']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  freeConsultation?: InputMaybe<Scalars['String']['input']>;
  hourlyPrice?: InputMaybe<Scalars['Float']['input']>;
  languages?: InputMaybe<Array<Scalars['String']['input']>>;
  paymentTypes?: InputMaybe<Array<AppclosePaymentType>>;
  retainer?: InputMaybe<AppcloseRetainerFeeType>;
  visibleInSearch?: InputMaybe<Scalars['Boolean']['input']>;
};

export type InvoiceClientHistory = {
  __typename: 'InvoiceClientHistory';
  email: Scalars['String']['output'];
};

export type InvoiceCreditMemoHistory = {
  __typename: 'InvoiceCreditMemoHistory';
  balanceDue: Scalars['Float']['output'];
  creditAmount: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  requestedAmount: Scalars['Float']['output'];
};

export type InvoiceFilterInput = {
  contactId?: InputMaybe<Scalars['ID']['input']>;
  destinationAccount?: InputMaybe<BankAccountClasses>;
  from?: InputMaybe<Scalars['DateTime']['input']>;
  hasMissedPayments?: InputMaybe<Scalars['Boolean']['input']>;
  isOverdue?: InputMaybe<Scalars['Boolean']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<InputMaybe<InvoiceStatusesTypes>>>;
  to?: InputMaybe<Scalars['DateTime']['input']>;
};

export type InvoiceHistoryType = {
  __typename: 'InvoiceHistoryType';
  client?: Maybe<InvoiceClientHistory>;
  creditMemo?: Maybe<InvoiceCreditMemoHistory>;
  date: Scalars['DateTime']['output'];
  partialPayment?: Maybe<InvoicePartialPaymentHistory>;
  status: InvoiceHistoryTypes;
};

/** Types of invoice history */
export enum InvoiceHistoryTypes {
  CREATED = 'CREATED',
  CREDIT_MEMO = 'CREDIT_MEMO',
  EDIT = 'EDIT',
  PAID = 'PAID',
  PARTIALLY_PAID = 'PARTIALLY_PAID',
  REFUNDED = 'REFUNDED',
  SENT = 'SENT',
  VIEWED = 'VIEWED',
  VOIDED = 'VOIDED',
}

export type InvoiceOverdueReminderInput = {
  emailText: Scalars['String']['input'];
  includeAccountStatementLink?: InputMaybe<Scalars['Boolean']['input']>;
  ongoingOverdueDayQty: Scalars['Int']['input'];
  overdueDayQty: Scalars['Int']['input'];
  stopToRemindAfterDayQty?: InputMaybe<Scalars['Int']['input']>;
  stopToRemindAfterSentQty?: InputMaybe<Scalars['Int']['input']>;
};

export type InvoiceOverdueReminderType = {
  __typename: 'InvoiceOverdueReminderType';
  emailText: Scalars['String']['output'];
  includeAccountStatementLink?: Maybe<Scalars['Boolean']['output']>;
  ongoingOverdueDayQty: Scalars['Int']['output'];
  overdueDayQty: Scalars['Int']['output'];
  reminderSchedule: Array<InvoiceReminderScheduleType>;
  stopToRemindAfterDayQty?: Maybe<Scalars['Int']['output']>;
  stopToRemindAfterSentQty?: Maybe<Scalars['Int']['output']>;
};

export type InvoicePartialPaymentHistory = {
  __typename: 'InvoicePartialPaymentHistory';
  dueAmount: Scalars['Float']['output'];
  paymentAmount: Scalars['Float']['output'];
};

export type InvoicePaymentReminderInput = {
  overdueReminder?: InputMaybe<InvoiceOverdueReminderInput>;
  prepaymentReminder?: InputMaybe<InvoicePrepaymentReminderInput>;
};

export type InvoicePaymentReminderType = {
  __typename: 'InvoicePaymentReminderType';
  overdueReminder?: Maybe<InvoiceOverdueReminderType>;
  prepaymentReminder?: Maybe<InvoicePrepaymentReminderType>;
};

export type InvoicePrepaymentReminderInput = {
  dayQtyBefore: Scalars['Int']['input'];
  emailText: Scalars['String']['input'];
  includeAccountStatementLink?: InputMaybe<Scalars['Boolean']['input']>;
};

export type InvoicePrepaymentReminderType = {
  __typename: 'InvoicePrepaymentReminderType';
  dayQtyBefore: Scalars['Int']['output'];
  emailText: Scalars['String']['output'];
  includeAccountStatementLink?: Maybe<Scalars['Boolean']['output']>;
  reminderSchedule: Array<InvoiceReminderScheduleType>;
};

export type InvoiceReminderScheduleType = {
  __typename: 'InvoiceReminderScheduleType';
  date: Scalars['DateTime']['output'];
  sent: Scalars['Boolean']['output'];
};

export type InvoiceStatFilterInput = {
  bankAccountClass?: InputMaybe<BankAccountClasses>;
  contactAmountOrder?: InputMaybe<OrderTypes>;
  contactId?: InputMaybe<Scalars['ID']['input']>;
  from?: InputMaybe<Scalars['DateTime']['input']>;
  overdue?: InputMaybe<Scalars['Boolean']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  statuses?: InputMaybe<Array<InvoiceStatusesTypes>>;
  take?: InputMaybe<Scalars['Int']['input']>;
  to?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Types of invoice statuses */
export enum InvoiceStatusesTypes {
  CREATED = 'CREATED',
  PAID = 'PAID',
  PARTIALLY_PAID = 'PARTIALLY_PAID',
  SENT = 'SENT',
  VIEWED = 'VIEWED',
  VOIDED = 'VOIDED',
}

export type InvoiceSubscriptionInput = {
  contactId?: InputMaybe<Scalars['ID']['input']>;
  destinationAccount?: InputMaybe<BankAccountClasses>;
  from?: InputMaybe<Scalars['DateTime']['input']>;
  hasMissedPayments?: InputMaybe<Scalars['Boolean']['input']>;
  includeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  isOverdue?: InputMaybe<Scalars['Boolean']['input']>;
  statuses?: InputMaybe<Array<InputMaybe<InvoiceStatusesTypes>>>;
  to?: InputMaybe<Scalars['DateTime']['input']>;
};

export type InvoiceType = {
  __typename: 'InvoiceType';
  accounts?: Maybe<Array<BankAccountType>>;
  balanceDue: Scalars['Float']['output'];
  contact: ContactType;
  createdAt: Scalars['DateTime']['output'];
  creditMemo?: Maybe<Array<PaymentType>>;
  destinationAccount?: Maybe<BankAccountClasses>;
  discount: Scalars['Float']['output'];
  dueDate: Scalars['DateTime']['output'];
  history: Array<InvoiceHistoryType>;
  id: Scalars['ID']['output'];
  internalMemo?: Maybe<Scalars['String']['output']>;
  invoiceNumber: Scalars['String']['output'];
  link: Scalars['String']['output'];
  message?: Maybe<Scalars['String']['output']>;
  missedPaymentQty?: Maybe<Scalars['Int']['output']>;
  originalAmount: Scalars['Float']['output'];
  overdueDayQty?: Maybe<Scalars['Int']['output']>;
  paidAmount: Scalars['Float']['output'];
  paymentReminder?: Maybe<InvoicePaymentReminderType>;
  percentageDiscount: Scalars['Boolean']['output'];
  qboSyncState?: Maybe<QboEntitySyncStatus>;
  requestedAmount: Scalars['Float']['output'];
  scheduledPayments?: Maybe<ScheduledPaymentsType>;
  status: InvoiceStatusesTypes;
  transactions?: Maybe<Array<PaymentType>>;
  voidInvoice?: Maybe<VoidInvoiceType>;
};

export type InvoicesStatType = {
  __typename: 'InvoicesStatType';
  amount: Scalars['Float']['output'];
  contacts: PaginatedContactStatList;
  paidAmount: Scalars['Float']['output'];
  totalInvoices: Scalars['Float']['output'];
};

/** Types of bank account business activity field */
export enum KybBusinessActivityType {
  LEGAL_SERVICES_AND_ATTORNEYS = 'LEGAL_SERVICES_AND_ATTORNEYS',
}

export type KybBusinessAddressInput = {
  address1: Scalars['String']['input'];
  address2?: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  state: UsaStates;
  zip: Scalars['String']['input'];
};

export type KybBusinessInfoInput = {
  businessActivity: KybBusinessActivityType;
  businessName: Scalars['String']['input'];
  businessStartDate: Scalars['Date']['input'];
  legalBusinessName: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  structure: KybCompanyType;
  taxId: Scalars['String']['input'];
  url?: InputMaybe<Scalars['String']['input']>;
};

export enum KybCompanyType {
  LLC = 'LLC',
  PRIVATE_CORPORATION = 'PRIVATE_CORPORATION',
  PRIVATE_PARTNERSHIP = 'PRIVATE_PARTNERSHIP',
  SINGLE_MEMBER_LLC = 'SINGLE_MEMBER_LLC',
  SOLE_PROPRIETORSHIP = 'SOLE_PROPRIETORSHIP',
}

export type KybInput = {
  bankAccount: BankAccountInput;
  businessAddress?: InputMaybe<KybBusinessAddressInput>;
  businessInfo: KybBusinessInfoInput;
  controlPerson: KybPersonInput;
  owners?: InputMaybe<Array<KybPersonInput>>;
};

export type KybPersonInput = {
  address1: Scalars['String']['input'];
  address2?: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  dateOfBirth: Scalars['Date']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  ownershipPercentage?: InputMaybe<Scalars['Int']['input']>;
  phoneNumber: Scalars['String']['input'];
  /** Required only for controlPerson block */
  relationship?: InputMaybe<PersonRelationship>;
  ssn: Scalars['String']['input'];
  state: Scalars['String']['input'];
  title: Scalars['String']['input'];
  zip: Scalars['String']['input'];
};

export type License = {
  __typename: 'License';
  licenseNumber?: Maybe<Scalars['String']['output']>;
  licensedIn?: Maybe<Array<Scalars['String']['output']>>;
  licensedSince?: Maybe<Scalars['String']['output']>;
  occupation: Scalars['String']['output'];
};

export type ListAppcloseEventsInput = {
  from: Scalars['DateTime']['input'];
  to: Scalars['DateTime']['input'];
};

export type ListAppcloseNotesInput = {
  beforeId?: InputMaybe<Scalars['String']['input']>;
  calendarIds?: InputMaybe<Array<Scalars['String']['input']>>;
  count?: InputMaybe<Scalars['Int']['input']>;
  from?: InputMaybe<Scalars['Date']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Scalars['Date']['input']>;
};

export type ListBlockedEmailsInput = {
  filter?: InputMaybe<FilterBlockedEmailsInput>;
  order?: InputMaybe<OrderBlockedEmailsInput>;
};

export type ListBlockedEmailsType = {
  __typename: 'ListBlockedEmailsType';
  count: Scalars['Int']['output'];
  items: Array<BlockedEmailType>;
};

export type ListChatMessagesInput = {
  afterId?: InputMaybe<Scalars['String']['input']>;
  beforeId?: InputMaybe<Scalars['String']['input']>;
  chatId: Scalars['String']['input'];
  count?: InputMaybe<Scalars['Int']['input']>;
  from?: InputMaybe<Scalars['DateTime']['input']>;
  nearId?: InputMaybe<Scalars['String']['input']>;
  onlySaved?: InputMaybe<Scalars['Boolean']['input']>;
  to?: InputMaybe<Scalars['DateTime']['input']>;
  /** Defaulf value: media,text,attachment */
  types?: InputMaybe<Array<ChatMessageTypes>>;
};

export type ListChatsFilterInput = {
  clientAccountId?: InputMaybe<Scalars['String']['input']>;
};

export type ListContactInput = {
  filter?: InputMaybe<FilterContactInput>;
  order?: InputMaybe<OrderContactInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type ListFilesInput = {
  filter?: InputMaybe<FilterFilesInput>;
  order?: InputMaybe<OrderFilesInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type ListInvoiceInput = {
  filter?: InputMaybe<InvoiceFilterInput>;
  order?: InputMaybe<OrderInvoiceInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type ListPaymentInput = {
  filter?: InputMaybe<FilterPaymentInput>;
  order?: InputMaybe<OrderPaymentInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type ListQboSyncHistoryFilters = {
  entityType?: InputMaybe<QboEntityTypes>;
  from?: InputMaybe<Scalars['DateTime']['input']>;
  isFailed?: InputMaybe<Scalars['Boolean']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ListQboSyncHistoryInput = {
  filters?: InputMaybe<ListQboSyncHistoryFilters>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type ListSharedChatMessagesInput = {
  afterId?: InputMaybe<Scalars['String']['input']>;
  beforeId?: InputMaybe<Scalars['String']['input']>;
  chatId: Scalars['String']['input'];
  count?: InputMaybe<Scalars['Int']['input']>;
  from?: InputMaybe<Scalars['DateTime']['input']>;
  nearId?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Scalars['DateTime']['input']>;
  /** Defaulf value: media,text,attachment */
  types?: InputMaybe<Array<ChatMessageTypes>>;
};

export type ListSignatureTemplatesInput = {
  filter?: InputMaybe<SignatureTemplatesFilterInput>;
  order?: InputMaybe<SignatureTemplatesOrderInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type ListSignaturesInput = {
  filter?: InputMaybe<SignatureFilterInput>;
  order?: InputMaybe<SignatureOrderInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type ListTeamMembersInput = {
  filter?: InputMaybe<FilterTeamMemberInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type ListUsersForBackOfficeType = {
  __typename: 'ListUsersForBackOfficeType';
  count: Scalars['Int']['output'];
  items: Array<UserForBackOfficeType>;
};

export type ListUsersForReviewType = {
  __typename: 'ListUsersForReviewType';
  count: Scalars['Int']['output'];
  items: Array<UserForReviewType>;
};

export type MarkMessageSeenInput = {
  messageId: Scalars['String']['input'];
  seenAt: Scalars['DateTime']['input'];
};

export type MessageObjectMetadataType = {
  __typename: 'MessageObjectMetadataType';
  from?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  requestType?: Maybe<AppcloseRequestTypes>;
  title?: Maybe<Scalars['String']['output']>;
  to?: Maybe<Scalars['DateTime']['output']>;
  type: AppcloseSharedObjectType;
};

export type MfaDataType = {
  __typename: 'MfaDataType';
  /** Available only in dev environments */
  code?: Maybe<Scalars['String']['output']>;
  codeTtl: Scalars['Float']['output'];
  maskedEmail?: Maybe<Scalars['String']['output']>;
  maskedPhoneNumber?: Maybe<Scalars['String']['output']>;
  token: Scalars['String']['output'];
};

export type MoveAppcloseNoteInput = {
  calendarId: Scalars['ID']['input'];
  date: Scalars['Date']['input'];
  noteId: Scalars['ID']['input'];
};

export type MoveFileInput = {
  destinationFolderId?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
};

export type MoveFolderInput = {
  destinationFolderId?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
};

export type Mutation = {
  __typename: 'Mutation';
  /**
   * Action: UPDATE
   * Resource: TEAM_MANAGEMENT
   */
  activateTeamMember: UserType;
  /**
   * Action: UPDATE
   * Resource: ADMIN
   */
  addBlockedEmail: BlockedEmailType;
  /**
   * Action: CREATE
   * Resource: CLIENT_CONNECTION_REQUEST
   */
  approveClientConnectionRequest: ClientConnectionRequestType;
  /**
   * Action: CREATE
   * Resource: CLIENT_REQUEST_RESOLVE
   */
  approveRequestToExportChat: SuccessResponseType;
  /**
   * Action: CREATE
   * Resource: CLIENT_REQUEST_RESOLVE
   */
  approveRequestToShareChat: SuccessResponseType;
  /**
   * Action: UPDATE
   * Resource: ADMIN
   */
  approveUserAfterReview: SuccessResponseType;
  /**
   * Action: UPDATE
   * Resource: ADMIN
   */
  archiveWaitListRecord: SuccessResponseType;
  /**
   * Action: UPDATE
   * Resource: FILES
   */
  attachEntity: FileStorageItemType;
  /**
   * Action: DELETE
   * Resource: ADMIN
   */
  blockUserAfterReview: SuccessResponseType;
  /**
   * Action: DELETE
   * Resource: CLIENT_CONNECTION_REQUEST
   */
  cancelClientConnectionRequest: ClientConnectionRequestType;
  /**
   * Action: UPDATE
   * Resource: INVOICE
   */
  cancelInvoiceSchedule: InvoiceType;
  /**
   * Action: UPDATE
   * Resource: SIGNATURE
   */
  cancelSignature: SuccessResponseType;
  /**
   * Action: UPDATE
   * Resource: PASSWORD
   */
  changePassword: AccountType;
  /**
   * Action: UPDATE
   * Resource: PROFILE
   */
  confirmEmail: SuccessResponseType;
  /**
   * Operating be connected with sendKyb
   *
   * Action: CREATE
   * Resource: BANK_ACCOUNT
   */
  connectTrustAccount: SuccessResponseType;
  /**
   * Action: CREATE
   * Resource: FILES
   */
  copyFile: FileStorageItemType;
  /**
   * Action: CREATE
   * Resource: APPCLOSE_CALENDAR
   */
  createCalendar: AppcloseCalendarType;
  /**
   * Action: CREATE
   * Resource: APPCLOSE_CALENDAR_EVENT
   */
  createCalendarEvent: AppcloseCalendarEventType;
  /**
   * Action: CREATE
   * Resource: CLIENT_CONNECTION_REQUEST
   */
  createClientConnectionRequest: ClientConnectionRequestType;
  /**
   * Action: CREATE
   * Resource: CONTACT
   */
  createContact: ContactType;
  /**
   * Action: CREATE
   * Resource: CONTACT_FILES
   */
  createContactFiles: Array<S3FileType>;
  /**
   * Action: CREATE
   * Resource: CREDIT_MEMO
   */
  createCreditMemo: PaymentType;
  /**
   * Action: CREATE
   * Resource: EXPORT_REQUEST
   */
  createExportRequest: SuccessResponseType;
  /**
   * Action: CREATE
   * Resource: FILES
   */
  createFiles: Array<FileStorageItemType>;
  /**
   * Action: CREATE
   * Resource: FOLDERS
   */
  createFolder: FileStorageItemType;
  /**
   * Action: CREATE
   * Resource: INVOICE
   */
  createInvoice: InvoiceType;
  /**
   * Action: CREATE
   * Resource: INVOICE_FILES
   */
  createInvoiceFiles: Array<S3FileType>;
  /**
   * Action: CREATE
   * Resource: APPCLOSE_NOTE
   */
  createNote: AppcloseCalendarNoteType;
  /**
   * Action: CREATE
   * Resource: PASSWORD
   */
  createPassword: AccountType;
  /**
   * Non applicable for CC and ACH payments
   *
   * Action: CREATE
   * Resource: PAYMENT
   */
  createPayment: PaymentType;
  /**
   * Action: CREATE
   * Resource: PAYMENT_FILES
   */
  createPaymentFiles: Array<S3FileType>;
  /**
   * Only for CC and ACH payments
   *
   * Action: CREATE
   * Resource: PAYMENT
   */
  createPaymentIntent: PaymentIntentType;
  /**
   * Action: CREATE
   * Resource: REFUND_PAYMENT
   */
  createRefundPayment: PaymentType;
  /**
   * Action: CREATE
   * Resource: SIGNATURE
   */
  createSignatureDraftWithTemplates: SignatureWithUrlType;
  /**
   * Action: CREATE
   * Resource: SIGNATURE
   */
  createSignatureDraftWithoutTemplates: SignatureWithUrlType;
  /**
   * Action: CREATE
   * Resource: BLOCKED
   */
  createSignatureTemplate: SignatureTemplateWithUrlType;
  /**
   * Action: UPDATE
   * Resource: SIGNATURE
   */
  createSigningEmbeddedUrl: SignatureUrlType;
  /**
   * Action: CREATE
   * Resource: TEAM_MANAGEMENT
   */
  createTeamMember: UserType;
  /**
   * Action: CREATE
   * Resource: VOID_INVOICE
   */
  createVoidInvoice: InvoiceType;
  /**
   * Action: CREATE
   * Resource: VOID_INVOICE_FILES
   */
  createVoidInvoiceFiles: Array<S3FileType>;
  /**
   * Action: UPDATE
   * Resource: TEAM_MANAGEMENT
   */
  deactivateTeamMember: UserType;
  /**
   * Action: DELETE
   * Resource: CLIENT_CONNECTION_REQUEST
   */
  declineClientConnectionRequest: ClientConnectionRequestType;
  /**
   * Action: CREATE
   * Resource: CLIENT_REQUEST_RESOLVE
   */
  declineRequestToExportChat: SuccessResponseType;
  /**
   * Action: CREATE
   * Resource: CLIENT_REQUEST_RESOLVE
   */
  declineRequestToShareChat: SuccessResponseType;
  /**
   * Action: DELETE
   * Resource: APPCLOSE_CALENDAR
   */
  deleteCalendar: SuccessResponseType;
  /**
   * Action: DELETE
   * Resource: APPCLOSE_CALENDAR_EVENT
   */
  deleteCalendarEvent: SuccessResponseType;
  /**
   * Action: DELETE
   * Resource: CLIENT
   */
  deleteClient: SuccessResponseType;
  /**
   * Action: DELETE
   * Resource: CONTACT
   */
  deleteContact: SuccessResponseType;
  /**
   * Action: DELETE
   * Resource: CONTACT_FILES
   */
  deleteContactFiles: SuccessResponseType;
  /**
   * Action: DELETE
   * Resource: FILES
   */
  deleteFile: SuccessResponseType;
  /**
   * Action: DELETE
   * Resource: FOLDERS
   */
  deleteFolder: SuccessResponseType;
  /**
   * Action: DELETE
   * Resource: INVOICE_FILES
   */
  deleteInvoiceFiles: SuccessResponseType;
  /**
   * Action: DELETE
   * Resource: APPCLOSE_NOTE
   */
  deleteNote: SuccessResponseType;
  /**
   * Action: DELETE
   * Resource: PAYMENT_FILES
   */
  deletePaymentFiles: SuccessResponseType;
  /**
   * Action: DELETE
   * Resource: VOID_INVOICE_FILES
   */
  deleteVoidInvoiceFiles: SuccessResponseType;
  /**
   * Action: UPDATE
   * Resource: PROFILE
   */
  disableMfa: ProfileType;
  /**
   * Action: DELETE
   * Resource: QBO
   */
  disconnectFromQbo: SuccessResponseType;
  /**
   * Action: UPDATE
   * Resource: PROFILE
   */
  enableMfa: ProfileType;
  exchangeAppcloseToken: AccountTokensType;
  exchangeTokenForReviewExportRequest: Scalars['String']['output'];
  exchangeTokenForReviewShareRequest: Scalars['String']['output'];
  /**
   * Action: UPDATE
   * Resource: SIGNATURE
   */
  finishSignature: SuccessResponseType;
  /**
   * Action: UPDATE
   * Resource: FILES
   */
  getSignedUrlForUploadingFiles: FileUploadingUrlsType;
  /**
   * Action: UPDATE
   * Resource: FIRM
   */
  hideBanner: FirmType;
  /**
   * Action: CREATE
   * Resource: CONTACT
   */
  importContacts: ContactsImportResultType;
  /**
   * Action: UPDATE
   * Resource: GOOGLE_FIRM_INTEGRATION
   */
  logoutGoogleFirmIntegration: SuccessResponseType;
  /**
   * Action: UPDATE
   * Resource: GOOGLE_PERSONAL_INTEGRATION
   */
  logoutGooglePersonalIntegration: SuccessResponseType;
  /**
   * Action: UPDATE
   * Resource: CHAT_MESSAGE
   */
  markMessageSeen: ChatMessageType;
  /**
   * Action: CREATE
   * Resource: FILES
   */
  moveFile: FileStorageItemType;
  /**
   * Action: UPDATE
   * Resource: FOLDERS
   */
  moveFolder: FileStorageItemType;
  /**
   * Action: UPDATE
   * Resource: APPCLOSE_NOTE
   */
  moveNote: AppcloseCalendarNoteType;
  /**
   * Action: UPDATE
   * Resource: BILL
   */
  payBill: PaymentIntentType;
  /**
   * Action: UPDATE
   * Resource: QBO
   */
  qboBulkResync: SuccessResponseType;
  /**
   * Action: UPDATE
   * Resource: CONTACT
   */
  qboBulkResyncContact: SuccessResponseType;
  /**
   * Action: UPDATE
   * Resource: CREDIT_MEMO
   */
  qboBulkResyncCreditMemo: SuccessResponseType;
  /**
   * Action: UPDATE
   * Resource: INVOICE
   */
  qboBulkResyncInvoice: SuccessResponseType;
  /**
   * Action: UPDATE
   * Resource: PAYMENT
   */
  qboBulkResyncPayment: SuccessResponseType;
  /**
   * Action: UPDATE
   * Resource: REFUND_PAYMENT
   */
  qboBulkResyncRefundPayment: SuccessResponseType;
  /**
   * Action: UPDATE
   * Resource: CONTACT
   */
  qboResyncContact: SuccessResponseType;
  /**
   * Action: UPDATE
   * Resource: CREDIT_MEMO
   */
  qboResyncCreditMemo: SuccessResponseType;
  /**
   * Action: UPDATE
   * Resource: INVOICE
   */
  qboResyncInvoice: SuccessResponseType;
  /**
   * Action: UPDATE
   * Resource: PAYMENT
   */
  qboResyncPayment: SuccessResponseType;
  /**
   * Action: UPDATE
   * Resource: REFUND_PAYMENT
   */
  qboResyncRefundPayment: SuccessResponseType;
  /**
   * Action: DELETE
   * Resource: ADMIN
   */
  removeBlockedEmail: SuccessResponseType;
  /**
   * Action: DELETE
   * Resource: BLOCKED
   */
  removeSignatureTemplate: SuccessResponseType;
  /**
   * Action: UPDATE
   * Resource: TEAM_MANAGEMENT
   */
  removeTeamMemberInvitation: SuccessResponseType;
  /**
   * Action: UPDATE
   * Resource: FILES
   */
  renameFile: FileStorageItemType;
  /**
   * Action: UPDATE
   * Resource: FOLDERS
   */
  renameFolder: FileStorageItemType;
  /**
   * Action: UPDATE
   * Resource: BANK_ACCOUNT
   */
  replaceBankAccount: SuccessResponseType;
  /**
   * Action: UPDATE
   * Resource: PROFILE
   */
  requestMfaByEmail: MfaDataType;
  /**
   * Action: UPDATE
   * Resource: PROFILE
   */
  requestMfaByPhoneNumber: MfaDataType;
  /**
   * Action: CREATE
   * Resource: SHARED_CHAT
   */
  requestToShareChat: SuccessResponseType;
  /**
   * Action: UPDATE
   * Resource: SHARED_CHAT
   */
  resendRequestToShareChat: SuccessResponseType;
  /**
   * Action: SEND
   * Resource: TEAM_MANAGEMENT
   */
  resendTeamMemberInvitation: SuccessResponseType;
  /**
   * Action: UPDATE
   * Resource: PASSWORD
   */
  resetPassword: AccountType;
  /**
   * Action: UPDATE
   * Resource: CHAT_MESSAGE
   */
  saveMessage: SuccessResponseType;
  /**
   * Action: UPDATE
   * Resource: QBO
   */
  saveQboMappingsPreferences: QboMappingsType;
  /**
   * Action: UPDATE
   * Resource: PAYMENT_REMINDER
   */
  scheduleInvoiceReminders: InvoiceType;
  sendCode: SendCodeResponseType;
  /**
   * Action: SEND
   * Resource: CREDIT_MEMO
   */
  sendCreditMemo: PaymentType;
  /**
   * Action: SEND
   * Resource: INVOICE
   */
  sendInvoice: InvoiceType;
  /**
   * Action: UPDATE
   * Resource: FIRM
   */
  sendKyb: SuccessResponseType;
  /**
   * Action: CREATE
   * Resource: CHAT_MESSAGE
   */
  sendMessage: ChatMessageType;
  /**
   * Action: SEND
   * Resource: PAYMENT
   */
  sendPayment: PaymentType;
  /**
   * Action: SEND
   * Resource: REFUND_PAYMENT
   */
  sendRefundPayment: PaymentType;
  /**
   * Action: REMIND
   * Resource: SIGNATURE
   */
  sendReminderForSignature: SuccessResponseType;
  sendResetPasswordEmail: SuccessResponseType;
  /**
   * Action: SEND
   * Resource: VOID_INVOICE
   */
  sendVoidInvoice: InvoiceType;
  /**
   * Action: UPDATE
   * Resource: CHAT
   */
  setChatTypingStatus: SuccessResponseType;
  /**
   * Action: UPDATE
   * Resource: CHAT
   */
  setOnlineStatus: OnlineStatusResponseType;
  setPrivacy?: Maybe<UserType>;
  signIn: AccountTokensType;
  signInWithToken: AccountTokensType;
  /**
   * Action: SIGN_OUT
   * Resource: USER
   */
  signOut: SuccessResponseType;
  /**
   * Action: DELETE
   * Resource: SHARED_CHAT
   */
  terminateSharedChatAccess: SuccessResponseType;
  /**
   * Action: CREATE
   * Resource: ANALYTIC_EVENT
   */
  trackAnalyticEvent: SuccessResponseType;
  /**
   * Action: UPDATE
   * Resource: SHARED_CHAT
   */
  trackOpenSharedChat: SuccessResponseType;
  trackPublicAnalyticEvent: SuccessResponseType;
  /**
   * Action: UPDATE
   * Resource: ADMIN
   */
  unarchiveWaitListRecord: SuccessResponseType;
  /**
   * Action: UPDATE
   * Resource: CHAT_MESSAGE
   */
  unsaveMessage: SuccessResponseType;
  /**
   * Action: UPDATE
   * Resource: APPCLOSE_CALENDAR
   */
  updateCalendar: AppcloseCalendarType;
  /**
   * Action: UPDATE
   * Resource: APPCLOSE_CALENDAR_EVENT
   */
  updateCalendarEvent: AppcloseCalendarEventType;
  /**
   * Action: UPDATE
   * Resource: CLIENT
   */
  updateClient: ClientType;
  /**
   * Action: UPDATE
   * Resource: CONTACT
   */
  updateContact: ContactType;
  /**
   * Action: UPDATE
   * Resource: CONTACT_FILES
   */
  updateContactFiles: SuccessResponseType;
  /**
   * Action: UPDATE
   * Resource: FIRM
   */
  updateFirm: FirmType;
  /**
   * Action: UPDATE
   * Resource: GOOGLE_FIRM_INTEGRATION
   */
  updateGoogleFileStorageSyncOptions: FileStorageSyncOptionType;
  /**
   * Action: UPDATE
   * Resource: INVOICE
   */
  updateInvoice: InvoiceType;
  /**
   * Action: UPDATE
   * Resource: INVOICE_FILES
   */
  updateInvoiceFiles: SuccessResponseType;
  /**
   * Action: UPDATE
   * Resource: APPCLOSE_NOTE
   */
  updateNote: AppcloseCalendarNoteType;
  /**
   * Action: UPDATE
   * Resource: PAYMENT_FILES
   */
  updatePaymentFiles: SuccessResponseType;
  /**
   * Action: UPDATE
   * Resource: PROFILE
   */
  updateProfile: ProfileType;
  /**
   * Action: UPDATE
   * Resource: PROFILE
   */
  updateProfileAvatar: ProfileType;
  /**
   * Action: UPDATE
   * Resource: SIGNATURE
   */
  updateSignature: SignatureUrlType;
  /**
   * Action: UPDATE
   * Resource: BLOCKED
   */
  updateSignatureTemplate: SignatureTemplateWithUrlType;
  /**
   * Action: UPDATE
   * Resource: TEAM_MANAGEMENT
   */
  updateTeamMember: UserType;
  /**
   * Action: UPDATE
   * Resource: VOID_INVOICE_FILES
   */
  updateVoidInvoiceFiles: SuccessResponseType;
  /**
   * Action: UPDATE
   * Resource: PROFILE
   */
  verifyEmail: Scalars['String']['output'];
  /**
   * Action: UPDATE
   * Resource: PROFILE
   */
  verifyPhoneNumber: SuccessResponseType;
};

export type MutationActivateTeamMemberArgs = {
  id: Scalars['ID']['input'];
};

export type MutationAddBlockedEmailArgs = {
  emailInput: EmailInput;
};

export type MutationApproveClientConnectionRequestArgs = {
  connectionRequestId: Scalars['String']['input'];
};

export type MutationApproveRequestToShareChatArgs = {
  approveRequestToShareChatInput: ApproveRequestToShareChatInput;
};

export type MutationApproveUserAfterReviewArgs = {
  approveUserAfterReviewInput: ApproveUserAfterReviewInput;
};

export type MutationArchiveWaitListRecordArgs = {
  email: Scalars['String']['input'];
};

export type MutationAttachEntityArgs = {
  attachEntityInput: FileAttachEntityInput;
};

export type MutationBlockUserAfterReviewArgs = {
  email: Scalars['String']['input'];
};

export type MutationCancelClientConnectionRequestArgs = {
  connectionRequestId: Scalars['String']['input'];
};

export type MutationCancelInvoiceScheduleArgs = {
  cancelInvoiceScheduleInput: CancelInvoiceScheduleInput;
};

export type MutationCancelSignatureArgs = {
  signatureIdInput: SignatureIdInput;
};

export type MutationChangePasswordArgs = {
  changePasswordInput: ChangePasswordInput;
};

export type MutationConfirmEmailArgs = {
  chainToken: Scalars['String']['input'];
  code: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type MutationConnectTrustAccountArgs = {
  connectTrustAccountInput: BankAccountInput;
};

export type MutationCopyFileArgs = {
  copyFileInput: CopyFileInput;
};

export type MutationCreateCalendarArgs = {
  createCalendarInput: CreateAppcloseCalendarInput;
};

export type MutationCreateCalendarEventArgs = {
  createCalendarEventInput: CreateAppcloseCalendarEventInput;
};

export type MutationCreateClientConnectionRequestArgs = {
  accountId: Scalars['String']['input'];
};

export type MutationCreateContactArgs = {
  createContactInput: CreateContactInput;
};

export type MutationCreateContactFilesArgs = {
  createFilesInput: CreateFilesInput;
};

export type MutationCreateCreditMemoArgs = {
  creditMemoInput: CreditMemoInput;
};

export type MutationCreateExportRequestArgs = {
  createExportRequestInput: CreateExportRequestInput;
};

export type MutationCreateFilesArgs = {
  createFilesInput: CreateFilesWithoutEntityInput;
};

export type MutationCreateFolderArgs = {
  createFolderInput: CreateFolderDataInput;
};

export type MutationCreateInvoiceArgs = {
  createInvoiceInput: CreateInvoiceInput;
};

export type MutationCreateInvoiceFilesArgs = {
  createFilesInput: CreateFilesInput;
};

export type MutationCreateNoteArgs = {
  createAppcloseNoteInput: CreateAppcloseNoteInput;
};

export type MutationCreatePasswordArgs = {
  createPasswordInput: CreatePasswordInput;
};

export type MutationCreatePaymentArgs = {
  createPaymentInput: CreatePaymentInput;
};

export type MutationCreatePaymentFilesArgs = {
  createFilesInput: CreateFilesInput;
};

export type MutationCreatePaymentIntentArgs = {
  createPaymentIntentInput: CreatePaymentIntentInput;
};

export type MutationCreateRefundPaymentArgs = {
  createRefundPaymentInput: CreateRefundPaymentInput;
};

export type MutationCreateSignatureDraftWithTemplatesArgs = {
  createSignatureDraftWithTemplatesInput: CreateSignatureDraftWithTemplatesInput;
};

export type MutationCreateSignatureDraftWithoutTemplatesArgs = {
  createSignatureDraftWithoutTemplatesInput: CreateSignatureDraftWithoutTemplatesInput;
};

export type MutationCreateSignatureTemplateArgs = {
  createSignatureTemplateInput: CreateSignatureTemplateInput;
};

export type MutationCreateTeamMemberArgs = {
  createTeamMemberInput: CreateTeamMemberInput;
};

export type MutationCreateVoidInvoiceArgs = {
  createVoidInvoiceInput: CreateVoidInvoiceInput;
};

export type MutationCreateVoidInvoiceFilesArgs = {
  createFilesInput: CreateFilesInput;
};

export type MutationDeactivateTeamMemberArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeclineClientConnectionRequestArgs = {
  connectionRequestId: Scalars['String']['input'];
};

export type MutationDeclineRequestToExportChatArgs = {
  declineRequestToExportChatInput: DeclineRequestToClientInput;
};

export type MutationDeclineRequestToShareChatArgs = {
  declineRequestToShareChatInput: DeclineRequestToClientInput;
};

export type MutationDeleteCalendarArgs = {
  calendarId: Scalars['String']['input'];
};

export type MutationDeleteCalendarEventArgs = {
  eventId: Scalars['String']['input'];
};

export type MutationDeleteClientArgs = {
  uuid: Scalars['String']['input'];
};

export type MutationDeleteContactArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteContactFilesArgs = {
  deleteFilesInput: DeleteFilesInput;
};

export type MutationDeleteFileArgs = {
  deleteFilesInput: DeleteFileInput;
};

export type MutationDeleteFolderArgs = {
  deleteFolderInput: DeleteFolderInput;
};

export type MutationDeleteInvoiceFilesArgs = {
  deleteFilesInput: DeleteFilesInput;
};

export type MutationDeleteNoteArgs = {
  noteId: Scalars['String']['input'];
};

export type MutationDeletePaymentFilesArgs = {
  deleteFilesInput: DeleteFilesInput;
};

export type MutationDeleteVoidInvoiceFilesArgs = {
  deleteFilesInput: DeleteFilesInput;
};

export type MutationDisableMfaArgs = {
  code: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type MutationEnableMfaArgs = {
  code: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type MutationExchangeAppcloseTokenArgs = {
  exchangeToken: Scalars['String']['input'];
};

export type MutationExchangeTokenForReviewExportRequestArgs = {
  token: Scalars['String']['input'];
};

export type MutationExchangeTokenForReviewShareRequestArgs = {
  token: Scalars['String']['input'];
};

export type MutationFinishSignatureArgs = {
  signatureIdInput: SignatureIdInput;
};

export type MutationGetSignedUrlForUploadingFilesArgs = {
  getSignedUrlForUploadingFilesInput: GetSignedUrlForUploadingFilesInput;
};

export type MutationHideBannerArgs = {
  hideBannerInput: HideBannerInput;
};

export type MutationImportContactsArgs = {
  signedUrls?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type MutationMarkMessageSeenArgs = {
  markMessageSeenInput: MarkMessageSeenInput;
};

export type MutationMoveFileArgs = {
  moveFileInput: MoveFileInput;
};

export type MutationMoveFolderArgs = {
  moveFolderInput: MoveFolderInput;
};

export type MutationMoveNoteArgs = {
  moveAppcloseNoteInput: MoveAppcloseNoteInput;
};

export type MutationPayBillArgs = {
  payBillInput: PayBillInput;
};

export type MutationQboBulkResyncArgs = {
  qboBulkResyncInput: QboBulkResyncInput;
};

export type MutationQboBulkResyncContactArgs = {
  ids: Array<Scalars['ID']['input']>;
};

export type MutationQboBulkResyncCreditMemoArgs = {
  id: Array<Scalars['ID']['input']>;
};

export type MutationQboBulkResyncInvoiceArgs = {
  id: Array<Scalars['ID']['input']>;
};

export type MutationQboBulkResyncPaymentArgs = {
  id: Array<Scalars['ID']['input']>;
};

export type MutationQboBulkResyncRefundPaymentArgs = {
  id: Array<Scalars['ID']['input']>;
};

export type MutationQboResyncContactArgs = {
  id: Scalars['ID']['input'];
};

export type MutationQboResyncCreditMemoArgs = {
  id: Scalars['ID']['input'];
};

export type MutationQboResyncInvoiceArgs = {
  id: Scalars['ID']['input'];
};

export type MutationQboResyncPaymentArgs = {
  id: Scalars['ID']['input'];
};

export type MutationQboResyncRefundPaymentArgs = {
  id: Scalars['ID']['input'];
};

export type MutationRemoveBlockedEmailArgs = {
  emailInput: EmailInput;
};

export type MutationRemoveSignatureTemplateArgs = {
  removeSignatureTemplateInput: RemoveSignatureTemplateInput;
};

export type MutationRemoveTeamMemberInvitationArgs = {
  id: Scalars['ID']['input'];
};

export type MutationRenameFileArgs = {
  renameFileInput: RenameFileInput;
};

export type MutationRenameFolderArgs = {
  renameFilesInput: RenameFolderDataInput;
};

export type MutationReplaceBankAccountArgs = {
  replaceBankAccountInput: ReplaceBankAccountInput;
};

export type MutationRequestMfaByEmailArgs = {
  chainToken?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
};

export type MutationRequestToShareChatArgs = {
  requestToShareChatInput: RequestToShareChatInput;
};

export type MutationResendRequestToShareChatArgs = {
  requestId: Scalars['String']['input'];
};

export type MutationResendTeamMemberInvitationArgs = {
  memberId: Scalars['ID']['input'];
};

export type MutationResetPasswordArgs = {
  resetPasswordInput: ResetPasswordInput;
};

export type MutationSaveMessageArgs = {
  messageId: Scalars['String']['input'];
};

export type MutationSaveQboMappingsPreferencesArgs = {
  qboSaveMappingsInput: QboSaveMappingsInput;
};

export type MutationScheduleInvoiceRemindersArgs = {
  scheduleInvoiceRemindersInput: ScheduleInvoiceRemindersInput;
};

export type MutationSendCodeArgs = {
  email: Scalars['String']['input'];
};

export type MutationSendCreditMemoArgs = {
  sendMemoInput: SendCreditMemoInput;
};

export type MutationSendInvoiceArgs = {
  sendInvoiceInput: SendInvoiceInput;
};

export type MutationSendKybArgs = {
  kybInput: KybInput;
};

export type MutationSendMessageArgs = {
  createChatMessageInput: CreateChatMessageInput;
};

export type MutationSendPaymentArgs = {
  sendPaymentInput: SendPaymentInput;
};

export type MutationSendRefundPaymentArgs = {
  sendRefundPaymentInput: SendRefundPaymentInput;
};

export type MutationSendReminderForSignatureArgs = {
  signatureIdInput: SignatureIdInput;
};

export type MutationSendResetPasswordEmailArgs = {
  sendResetPasswordEmail: EmailInput;
};

export type MutationSendVoidInvoiceArgs = {
  sendVoidInvoiceInput: SendVoidInvoiceInput;
};

export type MutationSetChatTypingStatusArgs = {
  chatId: Scalars['String']['input'];
};

export type MutationSetPrivacyArgs = {
  privacy: Scalars['Boolean']['input'];
};

export type MutationSignInArgs = {
  credentials: Credentials;
};

export type MutationSignInWithTokenArgs = {
  refreshToken: Scalars['String']['input'];
};

export type MutationTerminateSharedChatAccessArgs = {
  requestId: Scalars['String']['input'];
};

export type MutationTrackAnalyticEventArgs = {
  trackAnalyticEventInput: TrackAnalyticEventInput;
};

export type MutationTrackOpenSharedChatArgs = {
  input: TrackOpenSharedChatInput;
};

export type MutationTrackPublicAnalyticEventArgs = {
  trackPublicAnalyticEventInput: TrackPublicAnalyticEventInput;
};

export type MutationUnarchiveWaitListRecordArgs = {
  email: Scalars['String']['input'];
};

export type MutationUnsaveMessageArgs = {
  messageId: Scalars['String']['input'];
};

export type MutationUpdateCalendarArgs = {
  updateCalendarInput: UpdateAppcloseCalendarInput;
};

export type MutationUpdateCalendarEventArgs = {
  updateCalendarEventInput: UpdateAppcloseCalendarEvent;
};

export type MutationUpdateClientArgs = {
  updateClientInput: UpdateClientInput;
};

export type MutationUpdateContactArgs = {
  updateContactInput: UpdateContactInput;
};

export type MutationUpdateContactFilesArgs = {
  updateFilesInput: UpdateFilesInput;
};

export type MutationUpdateFirmArgs = {
  logo?: InputMaybe<Scalars['Upload']['input']>;
  updateFirmInput: UpdateFirmInput;
};

export type MutationUpdateGoogleFileStorageSyncOptionsArgs = {
  updateSyncOptionsInput: UpdateSyncOptionsInput;
};

export type MutationUpdateInvoiceArgs = {
  updateInvoiceInput: UpdateInvoiceInput;
};

export type MutationUpdateInvoiceFilesArgs = {
  updateFilesInput: UpdateFilesInput;
};

export type MutationUpdateNoteArgs = {
  updateAppcloseNoteInput: UpdateAppcloseNoteInput;
};

export type MutationUpdatePaymentFilesArgs = {
  updateFilesInput: UpdateFilesInput;
};

export type MutationUpdateProfileArgs = {
  updateProfileInput: UpdateProfileInput;
};

export type MutationUpdateProfileAvatarArgs = {
  avatarPublicId: Scalars['String']['input'];
};

export type MutationUpdateSignatureArgs = {
  signatureIdInput: SignatureIdInput;
};

export type MutationUpdateSignatureTemplateArgs = {
  updateSignatureTemplateInput: UpdateSignatureTemplateInput;
};

export type MutationUpdateTeamMemberArgs = {
  picture?: InputMaybe<Scalars['Upload']['input']>;
  updateTeamMemberInput: UpdateTeamMemberInput;
};

export type MutationUpdateVoidInvoiceFilesArgs = {
  updateFilesInput: UpdateFilesInput;
};

export type MutationVerifyEmailArgs = {
  chainToken?: InputMaybe<Scalars['String']['input']>;
  code: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type MutationVerifyPhoneNumberArgs = {
  code: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type NearestMessageInput = {
  chatId: Scalars['String']['input'];
  date: Scalars['Date']['input'];
  fromSharedChat?: InputMaybe<Scalars['Boolean']['input']>;
};

/** OnboardStatuses values */
export enum OnboardStatuses {
  BLOCKED = 'BLOCKED',
  COMPLETE = 'COMPLETE',
  EMAIL_VERIFICATION = 'EMAIL_VERIFICATION',
  LIMITED_ACCESS = 'LIMITED_ACCESS',
  PROFILE_FILLING = 'PROFILE_FILLING',
}

export type OnlineStatusResponseType = {
  __typename: 'OnlineStatusResponseType';
  /** Seconds until status switches to offline */
  ttl: Scalars['Int']['output'];
};

export type OrderBlockedEmailsInput = {
  createdAt?: InputMaybe<OrderTypes>;
};

export type OrderContactInput = {
  createdAt?: InputMaybe<OrderTypes>;
  email?: InputMaybe<OrderTypes>;
  name?: InputMaybe<OrderTypes>;
};

export type OrderFilesInput = {
  dateAdded?: InputMaybe<OrderTypes>;
  mimetype?: InputMaybe<OrderTypes>;
  name?: InputMaybe<OrderTypes>;
};

export type OrderFilesStorageInput = {
  name?: InputMaybe<OrderTypes>;
  size?: InputMaybe<OrderTypes>;
  updatedAt?: InputMaybe<OrderTypes>;
};

export type OrderInvoiceInput = {
  createdAt?: InputMaybe<OrderTypes>;
  dueDate?: InputMaybe<OrderTypes>;
  invoiceNumber?: InputMaybe<OrderTypes>;
  originalAmount?: InputMaybe<OrderTypes>;
  requestedAmount?: InputMaybe<OrderTypes>;
  status?: InputMaybe<OrderTypes>;
};

export type OrderPaymentInput = {
  amount?: InputMaybe<OrderTypes>;
  createdAt?: InputMaybe<OrderTypes>;
  invoiceNumber?: InputMaybe<OrderTypes>;
  paymentDate?: InputMaybe<OrderTypes>;
};

export type OrderRevenueStatInput = {
  balanceDue?: InputMaybe<OrderTypes>;
  name?: InputMaybe<OrderTypes>;
  paidAmount?: InputMaybe<OrderTypes>;
  revenueAbsolute?: InputMaybe<OrderTypes>;
  revenuePercent?: InputMaybe<OrderTypes>;
};

/** Possible order types */
export enum OrderTypes {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type OrderUsersInput = {
  appcloseCreatedAt?: InputMaybe<OrderTypes>;
  createdAt?: InputMaybe<OrderTypes>;
};

export type PaginatedContactList = {
  __typename: 'PaginatedContactList';
  items: Array<ContactType>;
  total: Scalars['Float']['output'];
};

export type PaginatedContactRevenueStatList = {
  __typename: 'PaginatedContactRevenueStatList';
  items: Array<ContactRevenueType>;
  total: Scalars['Int']['output'];
};

export type PaginatedContactStatList = {
  __typename: 'PaginatedContactStatList';
  items: Array<ContactStatType>;
  total: Scalars['Int']['output'];
};

export type PaginatedEntitySyncStatusType = {
  __typename: 'PaginatedEntitySyncStatusType';
  items: Array<QboEntitySyncStatusType>;
  total: Scalars['Int']['output'];
};

export type PaginatedFileFolderListType = {
  __typename: 'PaginatedFileFolderListType';
  items: Array<FileStorageItemType>;
  total: Scalars['Float']['output'];
};

export type PaginatedFileListType = {
  __typename: 'PaginatedFileListType';
  items: Array<S3FileType>;
  total: Scalars['Float']['output'];
};

export type PaginatedInvoiceList = {
  __typename: 'PaginatedInvoiceList';
  items: Array<InvoiceType>;
  total: Scalars['Int']['output'];
};

export type PaginatedPaymentsList = {
  __typename: 'PaginatedPaymentsList';
  items: Array<PaymentType>;
  total: Scalars['Float']['output'];
};

export type PaginatedSignatureListType = {
  __typename: 'PaginatedSignatureListType';
  items: Array<SignatureType>;
  total: Scalars['Int']['output'];
};

export type PaginatedSignatureTemplateList = {
  __typename: 'PaginatedSignatureTemplateList';
  items: Array<SignatureTemplateType>;
  total: Scalars['Int']['output'];
};

export type PaginatedTeamMembersListType = {
  __typename: 'PaginatedTeamMembersListType';
  items: Array<UserType>;
  total: Scalars['Int']['output'];
};

export type ParentAccountType = {
  __typename: 'ParentAccountType';
  avatar?: Maybe<AssetType>;
  clientId?: Maybe<Scalars['ID']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type PayBillInput = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  invoicesIds: Array<Scalars['String']['input']>;
  paymentMethod: PaymentMethodsTypes;
};

export type PaymentIntentType = {
  __typename: 'PaymentIntentType';
  clientSecret: Scalars['String']['output'];
  stripeAccountId: Scalars['String']['output'];
};

/** Types of payment methods */
export enum PaymentMethodsTypes {
  ACH = 'ACH',
  CASH = 'CASH',
  CC = 'CC',
  CHECK = 'CHECK',
  CREDIT_ISSUED = 'CREDIT_ISSUED',
  OTHER = 'OTHER',
  TRANSFER_FROM_TRUST = 'TRANSFER_FROM_TRUST',
  WIRE_TRANSFER = 'WIRE_TRANSFER',
}

export type PaymentSourceType = {
  __typename: 'PaymentSourceType';
  expirationDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  truncatedAccountNumber?: Maybe<Scalars['String']['output']>;
  truncatedPAN?: Maybe<Scalars['String']['output']>;
  type: PaymentMethodsTypes;
};

export type PaymentStatFilterInput = {
  contactId?: InputMaybe<Scalars['ID']['input']>;
  from?: InputMaybe<Scalars['DateTime']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Payment statuses */
export enum PaymentStatuses {
  CLEARED = 'CLEARED',
  FAILED = 'FAILED',
  PENDING = 'PENDING',
}

export type PaymentSubscriptionInput = {
  bankAccountClass?: InputMaybe<BankAccountClasses>;
  contactId?: InputMaybe<Scalars['ID']['input']>;
  from?: InputMaybe<Scalars['DateTime']['input']>;
  includeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  paymentMethod?: InputMaybe<PaymentMethodsTypes>;
  statuses?: InputMaybe<Array<PaymentStatuses>>;
  to?: InputMaybe<Scalars['DateTime']['input']>;
  types?: InputMaybe<Array<TransactionTypes>>;
};

export type PaymentType = {
  __typename: 'PaymentType';
  amount: Scalars['Float']['output'];
  bankAccount: BankAccountType;
  contact: ContactType;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  internalMemo?: Maybe<Scalars['String']['output']>;
  invoices?: Maybe<Array<InvoiceType>>;
  memoInvoice?: Maybe<InvoiceType>;
  memoNumber?: Maybe<Scalars['String']['output']>;
  memoText?: Maybe<Scalars['String']['output']>;
  parent?: Maybe<PaymentType>;
  parentAmount?: Maybe<Scalars['Float']['output']>;
  paymentDate: Scalars['Date']['output'];
  paymentMethod: PaymentMethodsTypes;
  qboSyncState?: Maybe<QboEntitySyncStatus>;
  refundAmount: Scalars['Float']['output'];
  refundNumber?: Maybe<Scalars['String']['output']>;
  refundPayment?: Maybe<PaymentType>;
  source?: Maybe<PaymentSourceType>;
  status: PaymentStatuses;
  truncatedPAN?: Maybe<Scalars['String']['output']>;
  type: TransactionTypes;
  updatedAt: Scalars['DateTime']['output'];
};

export type PaymentsStatType = {
  __typename: 'PaymentsStatType';
  amount: Scalars['Float']['output'];
  totalTransactions: Scalars['Int']['output'];
};

/** Possible period types */
export enum PeriodTypes {
  DAY = 'DAY',
  MONTH = 'MONTH',
  WEEK = 'WEEK',
}

export enum PersonRelationship {
  EXECUTIVE = 'EXECUTIVE',
  OWNER = 'OWNER',
}

export type PersonalInfo = {
  __typename: 'PersonalInfo';
  avatar?: Maybe<AssetType>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<GenderTypes>;
  joinedAt?: Maybe<Scalars['DateTime']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  mfaLoginEnabled?: Maybe<Scalars['Boolean']['output']>;
  middleName?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  phoneNumberCountry?: Maybe<Scalars['String']['output']>;
  phoneNumberType?: Maybe<AppclosePhoneTypes>;
  state?: Maybe<Scalars['String']['output']>;
  verifiedPhoneNumber?: Maybe<Scalars['Boolean']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type PhoneNumberInput = {
  isPrimary?: InputMaybe<Scalars['Boolean']['input']>;
  number: Scalars['String']['input'];
  type?: InputMaybe<PhoneTypes>;
};

export type PhoneNumberType = {
  __typename: 'PhoneNumberType';
  isPrimary?: Maybe<Scalars['Boolean']['output']>;
  number: Scalars['String']['output'];
  type?: Maybe<PhoneTypes>;
};

/** Possible phone types */
export enum PhoneTypes {
  FAX = 'FAX',
  HOME = 'HOME',
  MOBILE = 'MOBILE',
  OFFICE = 'OFFICE',
}

export type PictureInput = {
  color?: InputMaybe<Scalars['String']['input']>;
  original?: InputMaybe<Scalars['String']['input']>;
  thumbnail?: InputMaybe<Scalars['String']['input']>;
};

export type PictureType = {
  __typename: 'PictureType';
  color?: Maybe<Scalars['String']['output']>;
  original?: Maybe<Scalars['String']['output']>;
  thumbnail?: Maybe<Scalars['String']['output']>;
};

/** Principal titles */
export enum PrincipalTitles {
  ANALYST = 'Analyst',
  CEO = 'CEO',
  CFO = 'CFO',
  COO = 'COO',
  CTO = 'CTO',
  DIRECTOR = 'Director',
  MANAGER = 'Manager',
  OTHER = 'Other',
  OWNER = 'Owner',
  SUPERVISOR = 'Supervisor',
}

export type PrincipalType = {
  __typename: 'PrincipalType';
  address: AddressType;
  dateOfBirth: Scalars['Date']['output'];
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  ownershipPercent: Scalars['Int']['output'];
  phoneNumber: Scalars['String']['output'];
  title: PrincipalTitles;
};

export type ProfileType = {
  __typename: 'ProfileType';
  appcloseId: Scalars['String']['output'];
  companyInfo?: Maybe<CompanyInfo>;
  license?: Maybe<License>;
  personalInfo?: Maybe<PersonalInfo>;
  servicesAndFees?: Maybe<ServicesAndFees>;
};

/** Types of public analytic events */
export enum PublicAnalyticEvent {
  SIGN_UP_FORM_CLOSED = 'SIGN_UP_FORM_CLOSED',
  SIGN_UP_FORM_INTERACTION = 'SIGN_UP_FORM_INTERACTION',
  SIGN_UP_FORM_TIMER = 'SIGN_UP_FORM_TIMER',
  SIGN_UP_FORM_VISITED = 'SIGN_UP_FORM_VISITED',
}

export type PublicFirmType = {
  __typename: 'PublicFirmType';
  address?: Maybe<AddressType>;
  logo?: Maybe<PictureType>;
  name: Scalars['String']['output'];
  phoneNumber?: Maybe<PhoneNumberType>;
  website?: Maybe<Scalars['String']['output']>;
};

export type QboAccountType = {
  __typename: 'QboAccountType';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  type: QboAccountTypes;
};

/** Possible QBO account types */
export enum QboAccountTypes {
  BANK = 'BANK',
  CREDIT_CARD = 'CREDIT_CARD',
  EQUITY = 'EQUITY',
  EXPENSE = 'EXPENSE',
  FIXED_ASSET = 'FIXED_ASSET',
  INCOME = 'INCOME',
  OTHER_ASSET = 'OTHER_ASSET',
  OTHER_CURRENT_ASSETS = 'OTHER_CURRENT_ASSETS',
  OTHER_CURRENT_LIABILITIES = 'OTHER_CURRENT_LIABILITIES',
  OTHER_EXPENSES = 'OTHER_EXPENSES',
  OTHER_INCOME = 'OTHER_INCOME',
}

export type QboBulkResyncEntitiesInput = {
  entityType: QboEntityTypes;
  ids: Array<Scalars['ID']['input']>;
};

export type QboBulkResyncInput = {
  entities: Array<QboBulkResyncEntitiesInput>;
};

export type QboEntitiesInitialSyncState = {
  __typename: 'QboEntitiesInitialSyncState';
  finished: Scalars['Boolean']['output'];
};

export enum QboEntitySyncStatus {
  FAILED = 'FAILED',
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
}

export type QboEntitySyncStatusType = {
  __typename: 'QboEntitySyncStatusType';
  action: QboSyncHistoryAction;
  createdAt: Scalars['DateTime']['output'];
  entityId: Scalars['String']['output'];
  entityType: QboEntityTypes;
  id: Scalars['ID']['output'];
  idOrName?: Maybe<Scalars['String']['output']>;
  isFail: Scalars['Boolean']['output'];
};

/** CUSTOMER equals CONTACT in our system */
export enum QboEntityTypes {
  CREDIT_MEMO = 'CREDIT_MEMO',
  CUSTOMER = 'CUSTOMER',
  DEPOSIT = 'DEPOSIT',
  DISBURSEMENT = 'DISBURSEMENT',
  FIRM = 'FIRM',
  INVOICE = 'INVOICE',
  OTO = 'OTO',
  PAYMENT = 'PAYMENT',
  PAYMENT_REFUND = 'PAYMENT_REFUND',
}

export type QboIntegrationDetailsType = {
  __typename: 'QboIntegrationDetailsType';
  actualCompanyName?: Maybe<Scalars['String']['output']>;
  connected: Scalars['Boolean']['output'];
  connectionError?: Maybe<CommonAppErrors>;
  email?: Maybe<Scalars['String']['output']>;
  errorQty?: Maybe<Scalars['Float']['output']>;
  expectedCompanyName?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  syncQty?: Maybe<Scalars['Float']['output']>;
  syncStatus?: Maybe<QboSyncStatus>;
};

export type QboIntegrationDisabledType = {
  __typename: 'QboIntegrationDisabledType';
  firmId: Scalars['ID']['output'];
};

export type QboIntegrationEnabledType = {
  __typename: 'QboIntegrationEnabledType';
  email?: Maybe<Scalars['String']['output']>;
  firmId?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type QboMappingsResponseType = {
  __typename: 'QboMappingsResponseType';
  mappings?: Maybe<QboMappingsType>;
};

export type QboMappingsType = {
  __typename: 'QboMappingsType';
  commonInvoiceItem?: Maybe<Scalars['String']['output']>;
  creditCardFeesAccount?: Maybe<Scalars['String']['output']>;
  expenseItem?: Maybe<Scalars['String']['output']>;
  flatFeeItem?: Maybe<Scalars['String']['output']>;
  operatingAccount: Scalars['String']['output'];
  refundItem: Scalars['String']['output'];
  timeEntryItem?: Maybe<Scalars['String']['output']>;
  trustAccount?: Maybe<Scalars['String']['output']>;
  trustDepositAccount?: Maybe<Scalars['String']['output']>;
  trustDepositItem: Scalars['String']['output'];
};

export type QboResourcesDetailsType = {
  __typename: 'QboResourcesDetailsType';
  accounts: Array<QboAccountType>;
  defaultDiscountAccount?: Maybe<Scalars['ID']['output']>;
  serviceItems: Array<QboServiceItemType>;
};

export type QboSaveMappingsInput = {
  commonInvoiceItem?: InputMaybe<Scalars['String']['input']>;
  creditCardFeesAccount?: InputMaybe<Scalars['String']['input']>;
  expenseItem?: InputMaybe<Scalars['String']['input']>;
  flatFeeItem?: InputMaybe<Scalars['String']['input']>;
  operatingAccount: Scalars['String']['input'];
  refundItem: Scalars['String']['input'];
  timeEntryItem?: InputMaybe<Scalars['String']['input']>;
  trustAccount?: InputMaybe<Scalars['String']['input']>;
  trustDepositAccount?: InputMaybe<Scalars['String']['input']>;
  trustDepositItem: Scalars['String']['input'];
};

export type QboServiceItemType = {
  __typename: 'QboServiceItemType';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type QboSyncFinishedType = {
  __typename: 'QboSyncFinishedType';
  errorQty: Scalars['Float']['output'];
  firmId: Scalars['ID']['output'];
  success: Scalars['Boolean']['output'];
  syncQty: Scalars['Float']['output'];
};

export enum QboSyncHistoryAction {
  CREDIT_MEMO_CREATED = 'CREDIT_MEMO_CREATED',
  CREDIT_MEMO_CREATE_FAILED = 'CREDIT_MEMO_CREATE_FAILED',
  CREDIT_MEMO_INVOICE_NOT_EXPORTED = 'CREDIT_MEMO_INVOICE_NOT_EXPORTED',
  CREDIT_MEMO_MAPPINGS_MISSED = 'CREDIT_MEMO_MAPPINGS_MISSED',
  CREDIT_MEMO_NUMBER_DUPLICATED = 'CREDIT_MEMO_NUMBER_DUPLICATED',
  CREDIT_MEMO_PAYMENT_CREATE_FAILED = 'CREDIT_MEMO_PAYMENT_CREATE_FAILED',
  CUSTOMER_CREATED = 'CUSTOMER_CREATED',
  CUSTOMER_CREATE_FAILED = 'CUSTOMER_CREATE_FAILED',
  CUSTOMER_NAME_DUPLICATED = 'CUSTOMER_NAME_DUPLICATED',
  CUSTOMER_NOT_EXPORTED = 'CUSTOMER_NOT_EXPORTED',
  CUSTOMER_UPDATED = 'CUSTOMER_UPDATED',
  CUSTOMER_UPDATE_FAILED = 'CUSTOMER_UPDATE_FAILED',
  DEPOSIT_CREATED = 'DEPOSIT_CREATED',
  DEPOSIT_CREATE_FAILED = 'DEPOSIT_CREATE_FAILED',
  DEPOSIT_NO_MAPPINGS_FOUND = 'DEPOSIT_NO_MAPPINGS_FOUND',
  DEPOSIT_VALIDATION_ERROR = 'DEPOSIT_VALIDATION_ERROR',
  DISBURSEMENT_CLEARED = 'DISBURSEMENT_CLEARED',
  DISBURSEMENT_CLEAR_FAILED = 'DISBURSEMENT_CLEAR_FAILED',
  DISBURSEMENT_CLEAR_VALIDATION_ERROR = 'DISBURSEMENT_CLEAR_VALIDATION_ERROR',
  DISBURSEMENT_CREATED = 'DISBURSEMENT_CREATED',
  DISBURSEMENT_CREATE_FAILED = 'DISBURSEMENT_CREATE_FAILED',
  DISBURSEMENT_NEED_TO_PRINT = 'DISBURSEMENT_NEED_TO_PRINT',
  DISBURSEMENT_NOT_EXPORTED = 'DISBURSEMENT_NOT_EXPORTED',
  DISBURSEMENT_NO_MAPPINGS_FOUND = 'DISBURSEMENT_NO_MAPPINGS_FOUND',
  DISBURSEMENT_PRINTED = 'DISBURSEMENT_PRINTED',
  DISBURSEMENT_PRINT_STATUS_CHANGE_FAILED = 'DISBURSEMENT_PRINT_STATUS_CHANGE_FAILED',
  DISBURSEMENT_VALIDATION_ERROR = 'DISBURSEMENT_VALIDATION_ERROR',
  DISBURSEMENT_VOIDED = 'DISBURSEMENT_VOIDED',
  FIRM_AUTH_REFRESH_FAILED = 'FIRM_AUTH_REFRESH_FAILED',
  FIRM_AUTH_TOKEN_MISSED = 'FIRM_AUTH_TOKEN_MISSED',
  FIRM_MONTHLY_FEES_CREATED = 'FIRM_MONTHLY_FEES_CREATED',
  FIRM_MONTHLY_FEES_CREATE_FAILED = 'FIRM_MONTHLY_FEES_CREATE_FAILED',
  FIRM_MONTHLY_FEES_MAPPINGS_MISSED = 'FIRM_MONTHLY_FEES_MAPPINGS_MISSED',
  INVOICE_CREATED = 'INVOICE_CREATED',
  INVOICE_CREATE_FAILED = 'INVOICE_CREATE_FAILED',
  INVOICE_CUSTOMER_NOT_EXPORTED = 'INVOICE_CUSTOMER_NOT_EXPORTED',
  INVOICE_MAPPINGS_MISSED = 'INVOICE_MAPPINGS_MISSED',
  INVOICE_NOT_EXPORTED = 'INVOICE_NOT_EXPORTED',
  INVOICE_NUMBER_DUPLICATED = 'INVOICE_NUMBER_DUPLICATED',
  INVOICE_UPDATED = 'INVOICE_UPDATED',
  INVOICE_UPDATE_FAILED = 'INVOICE_UPDATE_FAILED',
  INVOICE_VOIDED = 'INVOICE_VOIDED',
  INVOICE_VOID_FAILED = 'INVOICE_VOID_FAILED',
  OTO_CREATED = 'OTO_CREATED',
  OTO_CREATE_FAILED = 'OTO_CREATE_FAILED',
  OTO_CUSTOMER_NOT_EXPORTED = 'OTO_CUSTOMER_NOT_EXPORTED',
  OTO_NO_MAPPINGS_FOUND = 'OTO_NO_MAPPINGS_FOUND',
  OTO_VALIDATION_ERROR = 'OTO_VALIDATION_ERROR',
  PAYMENT_CREATED = 'PAYMENT_CREATED',
  PAYMENT_CREATE_FAILED = 'PAYMENT_CREATE_FAILED',
  PAYMENT_CUSTOMER_NOT_EXPORTED = 'PAYMENT_CUSTOMER_NOT_EXPORTED',
  PAYMENT_NO_INVOICES_FOUND = 'PAYMENT_NO_INVOICES_FOUND',
  PAYMENT_REFUND_CREATED = 'PAYMENT_REFUND_CREATED',
  PAYMENT_REFUND_CREATE_FAILED = 'PAYMENT_REFUND_CREATE_FAILED',
  PAYMENT_REFUND_CUSTOMER_NOT_EXPORTED = 'PAYMENT_REFUND_CUSTOMER_NOT_EXPORTED',
  PAYMENT_REFUND_NO_INVOICES_FOUND = 'PAYMENT_REFUND_NO_INVOICES_FOUND',
  PAYMENT_REFUND_NO_MAPPINGS_FOUND = 'PAYMENT_REFUND_NO_MAPPINGS_FOUND',
}

/** Possible QBO sync statuses */
export enum QboSyncStatus {
  FAILED = 'FAILED',
  FINISHED = 'FINISHED',
  IN_PROGRESS = 'IN_PROGRESS',
}

export type Query = {
  __typename: 'Query';
  /**
   * Action: UPDATE
   * Resource: BILL
   */
  agreeScheduledBillIntent: ScheduledBillIntent;
  /**
   * Action: LIST
   * Resource: ADMIN
   */
  backOfficeExportRequestsInsights: BackOfficeExportRequestsInsightsType;
  /**
   * Action: LIST
   * Resource: ADMIN
   */
  backOfficeListUsers: ListUsersForBackOfficeType;
  /**
   * Action: LIST
   * Resource: ADMIN
   */
  backOfficeSharedChatInsights: BackOfficeSharedChatsInsightsType;
  /**
   * Action: LIST
   * Resource: ADMIN
   */
  backOfficeUsersInsights: BackOfficeUsersInsightsType;
  /**
   * Action: CREATE
   * Resource: INVOICE
   */
  calculateScheduledPayments: Array<ScheduledPaymentType>;
  /**
   * Action: READ
   * Resource: CLIENT
   */
  checkEmailForShareRequest: ResultOfCheckEmailForChareRequestType;
  /**
   * Action: READ
   * Resource: SIGNATURE
   */
  downloadSignatureAuditTrail: SignatureUrlType;
  /**
   * Action: READ
   * Resource: SIGNATURE
   */
  downloadSignatureDocumentOnly: SignatureUrlType;
  /**
   * Action: READ
   * Resource: SIGNATURE
   */
  downloadSignatureDocumentWithAuditTrail: SignatureUrlType;
  /**
   * Action: READ
   * Resource: PRIVATE
   */
  errorExample: CommonAppErrorsType;
  /**
   * Action: LIST
   * Resource: CHAT_MESSAGE
   */
  exportMessages: SuccessResponseType;
  /**
   * Action: LIST
   * Resource: CHAT_MESSAGE
   */
  exportSavedMessages: SuccessResponseType;
  /**
   * Action: CREATE
   * Resource: CLIENT
   */
  generateLink: Scalars['String']['output'];
  /**
   * Action: READ
   * Resource: APPCLOSE_SHARED_ENTITY
   */
  getAppcloseExpense: AppcloseExpenseType;
  /**
   * Action: READ
   * Resource: APPCLOSE_INSIGHTS
   */
  getAppcloseInsights: AppcloseInsightsType;
  /**
   * Action: READ
   * Resource: PROFILE
   */
  getAppcloseProfile: ProfileType;
  /**
   * Action: READ
   * Resource: APPCLOSE_SHARED_ENTITY
   */
  getAppcloseRequest: AppcloseRequestType;
  /**
   * Action: READ
   * Resource: BANK_ACCOUNT
   */
  getBankAccount: BankAccountType;
  /**
   * Action: READ
   * Resource: BILL
   */
  getBill: BillType;
  /**
   * Action: READ
   * Resource: APPCLOSE_CALENDAR_EVENT
   */
  getCalendarEvent: AppcloseCalendarEventType;
  /**
   * Action: READ
   * Resource: CHAT
   */
  getChat: ChatType;
  /**
   * Action: READ
   * Resource: CHAT
   */
  getChatParticipantStatuses: Array<ChatParticipantStatusType>;
  /**
   * Action: READ
   * Resource: CLIENT
   */
  getClient: ClientType;
  /**
   * Action: READ
   * Resource: INVOICES_STAT
   */
  getCommonPaymentStat: CommonPaymentStatType;
  /**
   * Action: READ
   * Resource: CONTACT
   */
  getContact: ContactType;
  /**
   * Action: READ
   * Resource: CONTACT
   */
  getContactQboSyncStatus?: Maybe<QboEntitySyncStatusType>;
  /**
   * Action: READ
   * Resource: INVOICES_STAT
   */
  getContactSummaryStat: ContactPaymentStatType;
  /**
   * Action: REPORT
   * Resource: CONTACT
   */
  getContactsCSVReport: FileUrlResponseType;
  /**
   * Action: READ
   * Resource: CREDIT_MEMO
   */
  getCreditMemoPDF: FileBase64ResponseType;
  /**
   * Action: READ
   * Resource: CREDIT_MEMO
   */
  getCreditMemoQboSyncStatus?: Maybe<QboEntitySyncStatusType>;
  /**
   * Action: READ
   * Resource: INVOICES_STAT
   */
  getDashboardStat: DashboardStatType;
  /**
   * Action: LIST
   * Resource: GOOGLE_FIRM_INTEGRATION
   */
  getDrivesFromGoogle: GetDrivesResponseType;
  /**
   * Action: READ
   * Resource: CLIENT_REQUEST_DETAILS
   */
  getExportChatRequestDetails: ExportChatRequestDetailsType;
  /**
   * Action: LIST
   * Resource: FILES
   */
  getFileAndFolderList: Array<FileStorageItemType>;
  /**
   * Action: LIST
   * Resource: FILES
   */
  getFileAndFolderPaginatedList: PaginatedFileFolderListType;
  /**
   * Action: LIST
   * Resource: FILES
   */
  getFileOrFolder?: Maybe<FileStorageItemType>;
  /**
   * Action: READ
   * Resource: FIRM
   */
  getFirm: FirmType;
  /**
   * Action: READ
   * Resource: GOOGLE_FIRM_INTEGRATION
   */
  getFirmGoogleAuthUrl: GoogleAuthUrlType;
  /**
   * Action: READ
   * Resource: FILES
   */
  getFolder?: Maybe<FileStorageItemType>;
  /**
   * Action: LIST
   * Resource: GOOGLE_FIRM_INTEGRATION
   */
  getFoldersFromGoogle: GetDriveFoldersResponseType;
  /**
   * Action: READ
   * Resource: GOOGLE_PERSONAL_INTEGRATION
   */
  getGoogleAuthUrl: GoogleAuthUrlType;
  /**
   * Action: READ
   * Resource: GOOGLE_FIRM_INTEGRATION
   */
  getGoogleFirmIntegrationStatus: GooglePersonalIntegrationStatusType;
  /**
   * Action: READ
   * Resource: GOOGLE_PERSONAL_INTEGRATION
   */
  getGooglePersonalIntegrationStatus: GooglePersonalIntegrationStatusType;
  /**
   * Action: LIST
   * Resource: GOOGLE_FIRM_INTEGRATION
   */
  getGoogleSyncOptions?: Maybe<FileStorageSyncOptionType>;
  getHealth: SuccessResponseType;
  /**
   * Action: READ
   * Resource: INVOICE
   */
  getInvoice: InvoiceType;
  /**
   * Action: READ
   * Resource: INVOICE
   */
  getInvoicePDF: FileUrlResponseType;
  /**
   * Action: READ
   * Resource: INVOICE
   */
  getInvoiceQboSyncStatus?: Maybe<QboEntitySyncStatusType>;
  /**
   * Action: REPORT
   * Resource: INVOICE
   */
  getInvoicesCSVReport: FileUrlResponseType;
  /**
   * Action: READ
   * Resource: INVOICES_STAT
   */
  getInvoicesStat: InvoicesStatType;
  /**
   * Action: READ
   * Resource: CHAT
   */
  getNearestMessage: IdentifiedEntityType;
  /**
   * Action: READ
   * Resource: FILES
   */
  getPath: Array<FolderPathType>;
  /**
   * Action: READ
   * Resource: PAYMENT
   */
  getPayment: PaymentType;
  /**
   * Action: READ
   * Resource: PAYMENT
   */
  getPaymentQboSyncStatus?: Maybe<QboEntitySyncStatusType>;
  /**
   * Action: REPORT
   * Resource: PAYMENT
   */
  getPaymentsCSVReport: FileUrlResponseType;
  /**
   * Action: READ
   * Resource: PAYMENTS_STAT
   */
  getPaymentsStat: PaymentsStatType;
  /**
   * Action: READ
   * Resource: PROFILE
   */
  getProfile: UserType;
  /**
   * Action: PUBLIC_READ
   * Resource: FIRM
   */
  getPublicFirm: PublicFirmType;
  /**
   * Action: READ
   * Resource: QBO
   */
  getQboInitialSyncState: QboEntitiesInitialSyncState;
  /**
   * Action: READ
   * Resource: QBO
   */
  getQboIntegrationDetails: QboIntegrationDetailsType;
  /**
   * Action: READ
   * Resource: QBO
   */
  getQboMappingsPreferences: QboMappingsResponseType;
  /**
   * Action: CREATE
   * Resource: QBO
   */
  getQboRedirectUrl: GetQboRedirectUrlResponse;
  /**
   * Action: READ
   * Resource: QBO
   */
  getQboResourcesDetails: QboResourcesDetailsType;
  /**
   * Action: READ
   * Resource: PAYMENT
   */
  getReceiptPDF: FileUrlResponseType;
  /**
   * Action: READ
   * Resource: REFUND_PAYMENT
   */
  getRefundPaymentQboSyncStatus?: Maybe<QboEntitySyncStatusType>;
  /**
   * Action: READ
   * Resource: REFUND_PAYMENT
   */
  getRefundReceiptPDF: FileBase64ResponseType;
  /**
   * Action: READ
   * Resource: INVOICES_STAT
   */
  getRevenueStat: RevenueStatType;
  /**
   * Action: READ
   * Resource: CLIENT_REQUEST_DETAILS
   */
  getShareChatRequestDetails: ShareChatRequestDetailsType;
  /**
   * Action: READ
   * Resource: SHARED_CHAT
   */
  getSharedChat: SharedChatType;
  /**
   * Action: READ
   * Resource: SIGNATURE
   */
  getSignature: SignatureType;
  /**
   * Action: READ
   * Resource: BLOCKED
   */
  getSignatureTemplate: SignatureTemplateType;
  /**
   * Action: UPDATE
   * Resource: FIRM
   */
  getSignedUrlForFirmLogo: FileUploadUrlType;
  /**
   * Action: CREATE
   * Resource: CONTACT
   */
  getSignedUrlForImportContacts: FileUploadUrlType;
  /**
   * Action: UPDATE
   * Resource: PROFILE
   */
  getSignedUrlForProfilePicture: FileUploadUrlType;
  /**
   * Action: UPDATE
   * Resource: TEAM_MANAGEMENT
   */
  getSignedUrlForTeamMemberPicture: FileUploadUrlType;
  /**
   * Action: READ
   * Resource: TEAM_MANAGEMENT
   */
  getTeamMember: UserType;
  /**
   * Action: READ
   * Resource: BANK_ACCOUNT
   */
  getTrustFeesAccountIntent: TrustFeesAccountConnectType;
  /**
   * Action: READ
   * Resource: GOOGLE_FIRM_INTEGRATION
   */
  googleCheckAccountStatus?: Maybe<SuccessResponseType>;
  /**
   * Action: READ
   * Resource: GOOGLE_FIRM_INTEGRATION
   */
  googleCheckFolderName?: Maybe<CheckFolderNameExistingType>;
  /**
   * Action: READ
   * Resource: PROFILE
   */
  hasNoProContent: Scalars['Boolean']['output'];
  /**
   * Action: LIST
   * Resource: BANK_ACCOUNT
   */
  listBankAccounts: Array<BankAccountType>;
  /**
   * Action: LIST
   * Resource: ADMIN
   */
  listBlockedEmails: ListBlockedEmailsType;
  /**
   * Action: LIST
   * Resource: APPCLOSE_CALENDAR_EVENT
   */
  listCalendarEvents: Array<AppcloseCalendarEventType>;
  /**
   * Action: LIST
   * Resource: APPCLOSE_CALENDAR
   */
  listCalendarGroups: Array<AppcloseCalendarGroupType>;
  /**
   * Action: LIST
   * Resource: APPCLOSE_CALENDAR
   */
  listCalendars: Array<AppcloseCalendarType>;
  /**
   * Action: LIST
   * Resource: CHAT_MESSAGE
   */
  listChatMessages: Array<ChatMessageType>;
  /**
   * Action: LIST
   * Resource: CHAT_MESSAGE
   */
  listChatMessagesFromSharedChat: Array<ChatMessageType>;
  /**
   * Action: LIST
   * Resource: CHAT
   */
  listChats: Array<ChatType>;
  /**
   * Action: LIST
   * Resource: CLIENT_CONNECTION_REQUEST
   */
  listClientConnectionRequests: ClientConnectionRequestList;
  /**
   * Action: LIST
   * Resource: CLIENT
   */
  listClients: ClientList;
  /**
   * Action: LIST
   * Resource: CONTACT_FILES
   */
  listContactFiles: PaginatedFileListType;
  /**
   * Action: LIST
   * Resource: CONTACT
   */
  listContacts: PaginatedContactList;
  /**
   * Action: LIST
   * Resource: INVOICE_FILES
   */
  listInvoiceFiles: PaginatedFileListType;
  /**
   * Action: LIST
   * Resource: INVOICE
   */
  listInvoices: PaginatedInvoiceList;
  /**
   * Action: LIST
   * Resource: APPCLOSE_NOTE
   */
  listNotes: Array<AppcloseCalendarNoteType>;
  /**
   * Action: LIST
   * Resource: PAYMENT_FILES
   */
  listPaymentFiles: PaginatedFileListType;
  /**
   * Action: LIST
   * Resource: PAYMENT
   */
  listPayments: PaginatedPaymentsList;
  /**
   * Action: READ
   * Resource: QBO
   */
  listQboSyncHistory: PaginatedEntitySyncStatusType;
  /**
   * Action: LIST
   * Resource: SHARED_CHAT
   */
  listSharedChats: Array<SharedChatType>;
  /**
   * Action: LIST
   * Resource: SIGNATURE
   */
  listSignatureFiles: PaginatedFileListType;
  /**
   * Action: LIST
   * Resource: BLOCKED
   */
  listSignatureTemplateFiles: PaginatedFileListType;
  /**
   * Action: LIST
   * Resource: BLOCKED
   */
  listSignatureTemplates: PaginatedSignatureTemplateList;
  /**
   * Action: LIST
   * Resource: SIGNATURE
   */
  listSignatures: PaginatedSignatureListType;
  /**
   * Action: LIST
   * Resource: TEAM_MANAGEMENT
   */
  listTeamMembers: PaginatedTeamMembersListType;
  /**
   * Action: LIST
   * Resource: ADMIN
   */
  listUsersForReview: ListUsersForReviewType;
  /**
   * Action: LIST
   * Resource: VOID_INVOICE_FILES
   */
  listVoidInvoiceFiles: PaginatedFileListType;
  /**
   * Action: READ
   * Resource: WEB_URLS
   */
  listWebUrls: WebUrlsType;
  /**
   * Action: LIST
   * Resource: CLIENT
   */
  searchAccount: AppcloseAccountList;
};

export type QueryAgreeScheduledBillIntentArgs = {
  agreeScheduledBillIntentInput: AgreeScheduledBillInput;
};

export type QueryBackOfficeListUsersArgs = {
  backOfficeListUsersInput: BackOfficeListUsersInput;
};

export type QueryCalculateScheduledPaymentsArgs = {
  calculateScheduledPaymentsInput: CalculateScheduledPaymentsInput;
};

export type QueryCheckEmailForShareRequestArgs = {
  email: Scalars['String']['input'];
};

export type QueryDownloadSignatureAuditTrailArgs = {
  signatureIdInput: SignatureIdInput;
};

export type QueryDownloadSignatureDocumentOnlyArgs = {
  signatureIdInput: SignatureIdInput;
};

export type QueryDownloadSignatureDocumentWithAuditTrailArgs = {
  signatureIdInput: SignatureIdInput;
};

export type QueryExportMessagesArgs = {
  exportMessagesInput: ExportMessagesInput;
};

export type QueryExportSavedMessagesArgs = {
  exportMessagesInput: ExportMessagesInput;
};

export type QueryGenerateLinkArgs = {
  generateLinkInput: GenerateLinkInput;
};

export type QueryGetAppcloseExpenseArgs = {
  expenseId: Scalars['String']['input'];
};

export type QueryGetAppcloseRequestArgs = {
  requestId: Scalars['String']['input'];
};

export type QueryGetBankAccountArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetBillArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetCalendarEventArgs = {
  eventId: Scalars['String']['input'];
};

export type QueryGetChatArgs = {
  chatId: Scalars['String']['input'];
};

export type QueryGetChatParticipantStatusesArgs = {
  chatId: Scalars['String']['input'];
};

export type QueryGetClientArgs = {
  uuid: Scalars['String']['input'];
};

export type QueryGetCommonPaymentStatArgs = {
  commonPaymentStatFilterInput?: InputMaybe<CommonPaymentStatFilterInput>;
};

export type QueryGetContactArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetContactQboSyncStatusArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetContactSummaryStatArgs = {
  contactPaymentStatFilterInput?: InputMaybe<ContactPaymentStatFilterInput>;
};

export type QueryGetContactsCsvReportArgs = {
  reportContactInput: ReportContactInput;
};

export type QueryGetCreditMemoPdfArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetCreditMemoQboSyncStatusArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetDashboardStatArgs = {
  dashboardStatFilterInput: DashboardStatFilterInput;
};

export type QueryGetDrivesFromGoogleArgs = {
  getDrivesInput?: InputMaybe<GetGoogleDrivesInput>;
};

export type QueryGetFileAndFolderListArgs = {
  fileFolderListInput?: InputMaybe<FileFolderListInput>;
};

export type QueryGetFileAndFolderPaginatedListArgs = {
  fileFolderListInput?: InputMaybe<FileFolderListInput>;
};

export type QueryGetFileOrFolderArgs = {
  getFileOrFolderInput: GetFileOfFolderInput;
};

export type QueryGetFolderArgs = {
  getFolderInput: GetFolderInput;
};

export type QueryGetFoldersFromGoogleArgs = {
  getDriveFolderInput?: InputMaybe<GetGoogleDriveFoldersInput>;
};

export type QueryGetInvoiceArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetInvoicePdfArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetInvoiceQboSyncStatusArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetInvoicesCsvReportArgs = {
  reportInvoiceInput: ReportInvoiceInput;
};

export type QueryGetInvoicesStatArgs = {
  invoiceStatFilterInput?: InputMaybe<InvoiceStatFilterInput>;
};

export type QueryGetNearestMessageArgs = {
  input: NearestMessageInput;
};

export type QueryGetPathArgs = {
  getPathInput: GetPathInput;
};

export type QueryGetPaymentArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetPaymentQboSyncStatusArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetPaymentsCsvReportArgs = {
  reportPaymentInput: ReportPaymentInput;
};

export type QueryGetPaymentsStatArgs = {
  paymentStatFilterInput?: InputMaybe<PaymentStatFilterInput>;
};

export type QueryGetQboRedirectUrlArgs = {
  getQboRedirectUrlInput: GetQboRedirectUrlInput;
};

export type QueryGetReceiptPdfArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetRefundPaymentQboSyncStatusArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetRefundReceiptPdfArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetRevenueStatArgs = {
  revenueStatInput?: InputMaybe<RevenueStatInput>;
};

export type QueryGetSharedChatArgs = {
  requestId: Scalars['String']['input'];
};

export type QueryGetSignatureArgs = {
  signatureIdInput: SignatureIdInput;
};

export type QueryGetSignatureTemplateArgs = {
  getSignatureTemplateInput: GetSignatureTemplateInput;
};

export type QueryGetSignedUrlForFirmLogoArgs = {
  fileData: FileDataToUploadInput;
};

export type QueryGetSignedUrlForImportContactsArgs = {
  fileData: FileDataToUploadInput;
};

export type QueryGetSignedUrlForProfilePictureArgs = {
  fileData: FileDataToUploadInput;
};

export type QueryGetSignedUrlForTeamMemberPictureArgs = {
  fileData: FileDataToUploadInput;
};

export type QueryGetTeamMemberArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGoogleCheckFolderNameArgs = {
  googleCheckFolderNameInput: CheckFolderNameExistingInput;
};

export type QueryListBlockedEmailsArgs = {
  listBlockedEmailsInput?: InputMaybe<ListBlockedEmailsInput>;
};

export type QueryListCalendarEventsArgs = {
  listAppcloseEventsInput: ListAppcloseEventsInput;
};

export type QueryListChatMessagesArgs = {
  listChatMessagesInput: ListChatMessagesInput;
};

export type QueryListChatMessagesFromSharedChatArgs = {
  listSharedChatMessagesInput: ListSharedChatMessagesInput;
};

export type QueryListChatsArgs = {
  listChatsFilterInput: ListChatsFilterInput;
};

export type QueryListClientConnectionRequestsArgs = {
  direction?: InputMaybe<ClientConnectionRequestDirection>;
};

export type QueryListContactFilesArgs = {
  listFilesInput?: InputMaybe<ListFilesInput>;
};

export type QueryListContactsArgs = {
  listContactInput?: InputMaybe<ListContactInput>;
};

export type QueryListInvoiceFilesArgs = {
  listFilesInput?: InputMaybe<ListFilesInput>;
};

export type QueryListInvoicesArgs = {
  listInvoiceInput?: InputMaybe<ListInvoiceInput>;
};

export type QueryListNotesArgs = {
  listAppcloseNotesInput: ListAppcloseNotesInput;
};

export type QueryListPaymentFilesArgs = {
  listFilesInput?: InputMaybe<ListFilesInput>;
};

export type QueryListPaymentsArgs = {
  listPaymentsInput?: InputMaybe<ListPaymentInput>;
};

export type QueryListQboSyncHistoryArgs = {
  listQboSyncHistoryInput: ListQboSyncHistoryInput;
};

export type QueryListSharedChatsArgs = {
  sharedChatsListFilterInput: SharedChatsListFilterInput;
};

export type QueryListSignatureFilesArgs = {
  listFilesInput?: InputMaybe<ListFilesInput>;
};

export type QueryListSignatureTemplateFilesArgs = {
  listFilesInput?: InputMaybe<ListFilesInput>;
};

export type QueryListSignatureTemplatesArgs = {
  listSignatureTemplatesInput: ListSignatureTemplatesInput;
};

export type QueryListSignaturesArgs = {
  listSignaturesInput: ListSignaturesInput;
};

export type QueryListTeamMembersArgs = {
  listTeamMembersInput?: InputMaybe<ListTeamMembersInput>;
};

export type QueryListVoidInvoiceFilesArgs = {
  listFilesInput?: InputMaybe<ListFilesInput>;
};

export type QuerySearchAccountArgs = {
  searchText: Scalars['String']['input'];
};

export type RemoveSignatureTemplateInput = {
  id: Scalars['ID']['input'];
};

export type RemovedEntitySubscriptionInput = {
  includeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type RemovedEntityType = {
  __typename: 'RemovedEntityType';
  id: Scalars['ID']['output'];
};

export type RenameFileInput = {
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type RenameFolderDataInput = {
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type ReplaceBankAccountInput = {
  bankAccount: BankAccountInput;
  bankAccountId: Scalars['ID']['input'];
};

export type ReportContactInput = {
  filter: FilterContactInput;
  order: OrderContactInput;
};

export type ReportInvoiceInput = {
  filter: InvoiceFilterInput;
  order: OrderInvoiceInput;
};

export type ReportPaymentInput = {
  filter: FilterPaymentInput;
  order: OrderPaymentInput;
};

export type RequestToShareChatInput = {
  clientId?: InputMaybe<Scalars['ID']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
};

export type ResetPasswordInput = {
  password: Scalars['String']['input'];
};

export type ResultOfCheckEmailForChareRequestType = {
  __typename: 'ResultOfCheckEmailForChareRequestType';
  name?: Maybe<Scalars['String']['output']>;
};

export type RevenueStatInput = {
  filter?: InputMaybe<FilterRevenueStatInput>;
  order?: InputMaybe<OrderRevenueStatInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type RevenueStatType = {
  __typename: 'RevenueStatType';
  contacts: PaginatedContactRevenueStatList;
  totalRevenueAbsolute?: Maybe<Scalars['Float']['output']>;
};

export type S3FileInput = {
  mimetype: Scalars['String']['input'];
  name: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

/** Possible file sources */
export enum S3FileSources {
  CONTACT = 'CONTACT',
  INVOICE = 'INVOICE',
  PAYMENT = 'PAYMENT',
  SIGNATURE = 'SIGNATURE',
  SIGNATURE_TEMPLATE = 'SIGNATURE_TEMPLATE',
  VOID_INVOICE = 'VOID_INVOICE',
}

export type S3FileType = {
  __typename: 'S3FileType';
  dateAdded: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  mimetype: Scalars['String']['output'];
  name: Scalars['String']['output'];
  /** file size in bytes */
  size?: Maybe<Scalars['Float']['output']>;
  source: S3FileSources;
  url: Scalars['String']['output'];
};

export type ScheduleInvoiceRemindersInput = {
  invoiceId: Scalars['ID']['input'];
  paymentReminder: InvoicePaymentReminderInput;
};

export type ScheduledBillIntent = {
  __typename: 'ScheduledBillIntent';
  clientSecret: Scalars['String']['output'];
  stripeAccountId: Scalars['String']['output'];
};

/** Types of scheduled payment statuses */
export enum ScheduledPaymentStatuses {
  FAILED = 'FAILED',
  PAID = 'PAID',
  SCHEDULED = 'SCHEDULED',
}

export type ScheduledPaymentType = {
  __typename: 'ScheduledPaymentType';
  amount: Scalars['Float']['output'];
  paymentDate: Scalars['Date']['output'];
  status: ScheduledPaymentStatuses;
};

export type ScheduledPaymentsSettingsInput = {
  installments: Scalars['Int']['input'];
  invoiceEvery: Scalars['Int']['input'];
  invoicePeriod: PeriodTypes;
  startDate: Scalars['Date']['input'];
};

export type ScheduledPaymentsSettingsType = {
  __typename: 'ScheduledPaymentsSettingsType';
  installments: Scalars['Int']['output'];
  invoiceEvery: Scalars['Int']['output'];
  invoicePeriod: PeriodTypes;
  startDate: Scalars['Date']['output'];
};

export type ScheduledPaymentsType = {
  __typename: 'ScheduledPaymentsType';
  plan?: Maybe<Array<ScheduledPaymentType>>;
  planApproved: Scalars['Boolean']['output'];
  settings?: Maybe<ScheduledPaymentsSettingsType>;
};

export type SendCodeResponseType = {
  __typename: 'SendCodeResponseType';
  verificationCode: Scalars['String']['output'];
};

export type SendCreditMemoInput = {
  /** e-mail carbon copy */
  cc?: InputMaybe<Scalars['String']['input']>;
  contactId: Scalars['ID']['input'];
  files?: InputMaybe<Array<Scalars['ID']['input']>>;
  id: Scalars['ID']['input'];
  includeAccountStatementLink?: InputMaybe<Scalars['Boolean']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
};

export type SendInvoiceInput = {
  /** e-mail carbon copy */
  cc?: InputMaybe<Scalars['String']['input']>;
  contactId: Scalars['ID']['input'];
  files?: InputMaybe<Array<Scalars['ID']['input']>>;
  id: Scalars['ID']['input'];
  includeAccountStatementLink?: InputMaybe<Scalars['Boolean']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
};

export type SendPaymentInput = {
  /** e-mail carbon copy */
  cc?: InputMaybe<Scalars['String']['input']>;
  contactId: Scalars['ID']['input'];
  files?: InputMaybe<Array<Scalars['ID']['input']>>;
  id: Scalars['ID']['input'];
  includeAccountStatementLink?: InputMaybe<Scalars['Boolean']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
};

export type SendRefundPaymentInput = {
  /** e-mail carbon copy */
  cc?: InputMaybe<Scalars['String']['input']>;
  contactId: Scalars['ID']['input'];
  files?: InputMaybe<Array<Scalars['ID']['input']>>;
  id: Scalars['ID']['input'];
  includeAccountStatementLink?: InputMaybe<Scalars['Boolean']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
};

export type SendVoidInvoiceInput = {
  /** e-mail carbon copy */
  cc?: InputMaybe<Scalars['String']['input']>;
  contactId: Scalars['ID']['input'];
  files?: InputMaybe<Array<Scalars['ID']['input']>>;
  id: Scalars['ID']['input'];
  includeAccountStatementLink?: InputMaybe<Scalars['Boolean']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
};

export type ServicesAndFees = {
  __typename: 'ServicesAndFees';
  brochuresCount?: Maybe<Scalars['Float']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  freeConsultation?: Maybe<Scalars['String']['output']>;
  hourlyPrice?: Maybe<Scalars['Float']['output']>;
  languages: Array<Scalars['String']['output']>;
  paymentTypes?: Maybe<Array<AppclosePaymentType>>;
  retainer?: Maybe<AppcloseRetainerFeeType>;
  visibleInSearch?: Maybe<Scalars['Boolean']['output']>;
};

export type ShareChatRequestDetailsType = {
  __typename: 'ShareChatRequestDetailsType';
  coparentOptions: Array<CoParentOptionType>;
  proFirstName: Scalars['ID']['output'];
  proLastName: Scalars['String']['output'];
  reason?: Maybe<Scalars['String']['output']>;
};

/** Sharing item type */
export enum ShareInfoItemType {
  ACCOUNT = 'ACCOUNT',
  CONTACT = 'CONTACT',
  EMAIL = 'EMAIL',
  RELATIVE = 'RELATIVE',
}

/** Possible shared chat statuses */
export enum SharedChatStatus {
  APPROVED = 'APPROVED',
  DECLINED = 'DECLINED',
  EXPIRED = 'EXPIRED',
  PENDING = 'PENDING',
}

export type SharedChatType = {
  __typename: 'SharedChatType';
  coparent?: Maybe<ParentAccountType>;
  declineReason?: Maybe<Scalars['String']['output']>;
  hasNewMessages: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isNew: Scalars['Boolean']['output'];
  parent: ParentAccountType;
  requestedAt: Scalars['DateTime']['output'];
  sharedAt?: Maybe<Scalars['DateTime']['output']>;
  sharedUntilAt?: Maybe<Scalars['DateTime']['output']>;
  status: SharedChatStatus;
};

export type SharedChatsInsightsType = {
  __typename: 'SharedChatsInsightsType';
  activeCount: Scalars['Int']['output'];
  newCount: Scalars['Int']['output'];
  unreadCount: Scalars['Int']['output'];
};

export type SharedChatsListFilterInput = {
  clientId?: InputMaybe<Scalars['ID']['input']>;
  date?: InputMaybe<Scalars['DateTime']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<SharedChatStatus>>;
};

export type SignInConfirmation = {
  code: Scalars['String']['input'];
  email: Scalars['String']['input'];
  remember: Scalars['Boolean']['input'];
};

export type SignatureFilterInput = {
  search?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<SignatureStatuses>>;
};

export type SignatureIdInput = {
  id: Scalars['ID']['input'];
};

export type SignatureOrderInput = {
  createdAt?: InputMaybe<OrderTypes>;
  status?: InputMaybe<OrderTypes>;
  title?: InputMaybe<OrderTypes>;
};

/** Possible signature statuses */
export enum SignatureStatuses {
  DECLINED = 'DECLINED',
  DECLINE_REQUEST_SENT = 'DECLINE_REQUEST_SENT',
  DRAFT = 'DRAFT',
  ERROR = 'ERROR',
  NOT_PREPARED = 'NOT_PREPARED',
  PARTIALLY_SIGNED = 'PARTIALLY_SIGNED',
  SENT = 'SENT',
  SIGNED = 'SIGNED',
}

export type SignatureSubscriptionInput = {
  includeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  statuses?: InputMaybe<Array<SignatureStatuses>>;
};

/** Possible signature template statuses */
export enum SignatureTemplateStatuses {
  DRAFT = 'DRAFT',
  ERROR = 'ERROR',
  READY = 'READY',
}

export type SignatureTemplateSubscriptionInput = {
  includeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  statuses?: InputMaybe<Array<SignatureTemplateStatuses>>;
};

export type SignatureTemplateType = {
  __typename: 'SignatureTemplateType';
  completeInOrder: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  signerRoles: Array<SignerRoleTemplateType>;
  status: SignatureTemplateStatuses;
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type SignatureTemplateWithUrlType = {
  __typename: 'SignatureTemplateWithUrlType';
  template: SignatureTemplateType;
  templateUrl: SignatureUrlType;
};

export type SignatureTemplatesFilterInput = {
  search?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<SignatureTemplateStatuses>>;
};

export type SignatureTemplatesOrderInput = {
  createdAt?: InputMaybe<OrderTypes>;
  status?: InputMaybe<OrderTypes>;
};

export type SignatureType = {
  __typename: 'SignatureType';
  completeInOrder: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  hasWithoutServiceInfoVersion?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  nonSignatoryRecipients?: Maybe<Array<Scalars['String']['output']>>;
  signers: Array<SignerType>;
  status: SignatureStatuses;
  template?: Maybe<SignatureTemplateType>;
  text?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  waitForPdfUpdate: Scalars['Boolean']['output'];
};

export type SignatureUrlType = {
  __typename: 'SignatureUrlType';
  expiresAt: Scalars['DateTime']['output'];
  url: Scalars['String']['output'];
};

export type SignatureWithUrlType = {
  __typename: 'SignatureWithUrlType';
  signature: SignatureType;
  signatureUrl: SignatureUrlType;
};

export type SignerInput = {
  contactId?: InputMaybe<Scalars['ID']['input']>;
  externalSigner?: InputMaybe<ExternalSignerInput>;
  teamMemberId?: InputMaybe<Scalars['ID']['input']>;
};

export type SignerRoleTemplateInput = {
  roleName: Scalars['String']['input'];
};

export type SignerRoleTemplateType = {
  __typename: 'SignerRoleTemplateType';
  id: Scalars['ID']['output'];
  roleName: Scalars['String']['output'];
};

/** Possible signer statuses */
export enum SignerStatuses {
  DECLINED = 'DECLINED',
  NOT_PREPARED = 'NOT_PREPARED',
  PREPARED = 'PREPARED',
  SENT = 'SENT',
  SIGNED = 'SIGNED',
  VIEWED = 'VIEWED',
}

export type SignerTemplateInput = {
  contactId?: InputMaybe<Scalars['ID']['input']>;
  externalSigner?: InputMaybe<ExternalSignerInput>;
  roleId: Scalars['ID']['input'];
  teamMemberId?: InputMaybe<Scalars['ID']['input']>;
};

export type SignerType = {
  __typename: 'SignerType';
  contact?: Maybe<ContactType>;
  createdAt: Scalars['DateTime']['output'];
  externalSigner?: Maybe<ExternalSignerType>;
  id: Scalars['ID']['output'];
  order?: Maybe<Scalars['Float']['output']>;
  roleName?: Maybe<Scalars['String']['output']>;
  status: SignerStatuses;
  teamMember?: Maybe<UserType>;
  updatedAt: Scalars['DateTime']['output'];
};

export enum StripeBankAccountStatus {
  CONNECTED = 'CONNECTED',
  FEE_ACCOUNT_PROCESSING = 'FEE_ACCOUNT_PROCESSING',
  FEE_ACCOUNT_REQUIRED = 'FEE_ACCOUNT_REQUIRED',
  MIGRATION_REQUIRED = 'MIGRATION_REQUIRED',
}

export enum StripeKybStatus {
  APPROVED = 'APPROVED',
  BLOCKED = 'BLOCKED',
  FAILURE = 'FAILURE',
  SENT = 'SENT',
}

export type Subscription = {
  __typename: 'Subscription';
  /**
   * Action: READ
   * Resource: BANK_ACCOUNT
   */
  bankAccountCreated: BankAccountType;
  /**
   * Action: READ
   * Resource: BANK_ACCOUNT
   */
  bankAccountDeleted: RemovedEntityType;
  /**
   * Action: READ
   * Resource: BANK_ACCOUNT
   */
  bankAccountUpdated: BankAccountType;
  /**
   * Action: READ
   * Resource: APPCLOSE_CALENDAR_EVENT
   */
  calendarEventCreated: AppcloseCalendarEventType;
  /**
   * Action: READ
   * Resource: APPCLOSE_CALENDAR_EVENT
   */
  calendarEventDeleted: RemovedEntityType;
  /**
   * Action: READ
   * Resource: APPCLOSE_CALENDAR_EVENT
   */
  calendarEventUpdated: AppcloseCalendarEventType;
  /**
   * Action: READ
   * Resource: CHAT_MESSAGE
   */
  chatMessageCreated: ChatMessageType;
  /**
   * Action: READ
   * Resource: CHAT_MESSAGE
   */
  chatMessageStatusUpdated: ChatMessageStatusUpdatedType;
  /**
   * Action: READ
   * Resource: CHAT
   */
  chatParticipantStatusChanged: ChatParticipantStatusType;
  /**
   * Action: READ
   * Resource: CHAT
   */
  chatTypingStatus: TypingInChatStatusType;
  /**
   * Action: READ
   * Resource: CLIENT
   */
  clientCreated: ClientType;
  /**
   * Action: READ
   * Resource: CLIENT
   */
  clientDeleted: RemovedEntityType;
  /**
   * Action: READ
   * Resource: CLIENT_CONNECTION_REQUEST
   */
  connectionRequestCreated: ClientConnectionRequestType;
  /**
   * Action: READ
   * Resource: CONTACT
   */
  contactCreated: ContactType;
  /**
   * Action: READ
   * Resource: CONTACT
   */
  contactDeleted: RemovedEntityType;
  /**
   * Action: READ
   * Resource: CONTACT
   */
  contactUpdated: ContactType;
  /**
   * Action: READ
   * Resource: FILES
   */
  fileCreated: S3FileType;
  /**
   * Action: READ
   * Resource: FILES
   */
  fileDeleted: RemovedEntityType;
  /**
   * Action: READ
   * Resource: FILES
   */
  fileStorageCreated: FileStorageItemType;
  /**
   * Action: READ
   * Resource: FILES
   */
  fileStorageDeleted: RemovedEntityType;
  /**
   * Action: READ
   * Resource: GOOGLE_FIRM_INTEGRATION
   */
  fileStorageSyncOptionUpdated: FileStorageSyncOptionType;
  /**
   * Action: READ
   * Resource: FILES
   */
  fileStorageUpdated: FileStorageItemType;
  /**
   * Action: READ
   * Resource: INVOICES_STAT
   */
  financialTilesUpdated: FinancialTilesUpdatedType;
  /**
   * Action: READ
   * Resource: FIRM
   */
  firmUpdated: FirmType;
  /**
   * Action: READ
   * Resource: GOOGLE_FIRM_INTEGRATION
   */
  googleFirmIntegrationStatusUpdated: GooglePersonalIntegrationStatusType;
  /**
   * Action: READ
   * Resource: GOOGLE_PERSONAL_INTEGRATION
   */
  googlePersonalIntegrationStatusUpdated: GooglePersonalIntegrationStatusType;
  /**
   * Action: READ
   * Resource: INVOICE
   */
  invoiceCreated: InvoiceType;
  /**
   * Action: READ
   * Resource: INVOICE
   */
  invoiceUpdated: InvoiceType;
  /**
   * Action: READ
   * Resource: PAYMENT
   */
  paymentCreated: PaymentType;
  /**
   * Action: READ
   * Resource: PAYMENT
   */
  paymentUpdated: PaymentType;
  /**
   * Action: READ
   * Resource: PROFILE
   */
  profileUpdated: UserType;
  /**
   * Action: READ
   * Resource: QBO
   */
  qboInitialEntitiesSyncFinished: QboEntitiesInitialSyncState;
  /**
   * Action: READ
   * Resource: QBO
   */
  qboIntegrationDisabled: QboIntegrationDisabledType;
  /**
   * Action: READ
   * Resource: QBO
   */
  qboIntegrationEnabled: QboIntegrationEnabledType;
  /**
   * Action: READ
   * Resource: QBO
   */
  qboSyncFinished: QboSyncFinishedType;
  /**
   * Action: READ
   * Resource: SHARED_CHAT
   */
  sharedChatCreated: SharedChatType;
  /**
   * Action: READ
   * Resource: SHARED_CHAT
   */
  sharedChatUpdated: SharedChatType;
  /**
   * Action: READ
   * Resource: SIGNATURE
   */
  signatureCreated: SignatureType;
  /**
   * Action: READ
   * Resource: SIGNATURE
   */
  signatureDeleted: RemovedEntityType;
  /**
   * Action: READ
   * Resource: BLOCKED
   */
  signatureTemplateCreated: SignatureTemplateType;
  /**
   * Action: READ
   * Resource: BLOCKED
   */
  signatureTemplateRemoved: RemovedEntityType;
  /**
   * Action: READ
   * Resource: BLOCKED
   */
  signatureTemplateUpdated: SignatureTemplateType;
  /**
   * Action: READ
   * Resource: SIGNATURE
   */
  signatureUpdated: SignatureType;
  /**
   * Action: READ
   * Resource: TEAM_MANAGEMENT
   */
  teamMemberActivated: UserType;
  /**
   * Action: READ
   * Resource: TEAM_MANAGEMENT
   */
  teamMemberCreated: UserType;
  /**
   * Action: READ
   * Resource: TEAM_MANAGEMENT
   */
  teamMemberDeactivated: UserType;
  /**
   * Action: READ
   * Resource: TEAM_MANAGEMENT
   */
  teamMemberRemoved: RemovedEntityType;
  /**
   * Action: READ
   * Resource: TEAM_MANAGEMENT
   */
  teamMemberUpdated: UserType;
};

export type SubscriptionContactCreatedArgs = {
  contactSubscriptionInput: ContactSubscriptionInput;
};

export type SubscriptionContactDeletedArgs = {
  removedEntitySubscriptionInput: RemovedEntitySubscriptionInput;
};

export type SubscriptionContactUpdatedArgs = {
  contactSubscriptionInput: ContactSubscriptionInput;
};

export type SubscriptionFileCreatedArgs = {
  fileSubscriptionInput: FileSubscriptionInput;
};

export type SubscriptionFileDeletedArgs = {
  fileSubscriptionInput: FileSubscriptionInput;
};

export type SubscriptionFileStorageCreatedArgs = {
  fileStorageSubscriptionInput: FileFolderListFilter;
};

export type SubscriptionFileStorageDeletedArgs = {
  fileStorageSubscriptionInput: FileFolderListFilter;
};

export type SubscriptionFileStorageUpdatedArgs = {
  fileStorageSubscriptionInput: FileFolderListFilter;
};

export type SubscriptionInvoiceCreatedArgs = {
  invoiceSubscriptionInput: InvoiceSubscriptionInput;
};

export type SubscriptionInvoiceUpdatedArgs = {
  invoiceSubscriptionInput: InvoiceSubscriptionInput;
};

export type SubscriptionPaymentCreatedArgs = {
  paymentSubscriptionInput: PaymentSubscriptionInput;
};

export type SubscriptionPaymentUpdatedArgs = {
  paymentSubscriptionInput: PaymentSubscriptionInput;
};

export type SubscriptionSignatureCreatedArgs = {
  signatureSubscriptionInput: SignatureSubscriptionInput;
};

export type SubscriptionSignatureDeletedArgs = {
  removedEntitySubscriptionInput: RemovedEntitySubscriptionInput;
};

export type SubscriptionSignatureTemplateCreatedArgs = {
  signatureTemplateSubscriptionInput: SignatureTemplateSubscriptionInput;
};

export type SubscriptionSignatureTemplateRemovedArgs = {
  removedEntitySubscriptionInput: RemovedEntitySubscriptionInput;
};

export type SubscriptionSignatureTemplateUpdatedArgs = {
  signatureTemplateSubscriptionInput: SignatureTemplateSubscriptionInput;
};

export type SubscriptionSignatureUpdatedArgs = {
  signatureSubscriptionInput: SignatureSubscriptionInput;
};

export type SubscriptionTeamMemberActivatedArgs = {
  teamMemberSubscriptionInput: TeamMemberSubscriptionInput;
};

export type SubscriptionTeamMemberCreatedArgs = {
  teamMemberSubscriptionInput: TeamMemberSubscriptionInput;
};

export type SubscriptionTeamMemberDeactivatedArgs = {
  teamMemberSubscriptionInput: TeamMemberSubscriptionInput;
};

export type SubscriptionTeamMemberRemovedArgs = {
  removedEntitySubscriptionInput: RemovedEntitySubscriptionInput;
};

export type SubscriptionTeamMemberUpdatedArgs = {
  teamMemberSubscriptionInput: TeamMemberSubscriptionInput;
};

export type SuccessResponseType = {
  __typename: 'SuccessResponseType';
  success: Scalars['Boolean']['output'];
};

/** Possible team member access levels */
export enum TeamMemberRoles {
  ATTORNEY = 'ATTORNEY',
  BILLABLE_ADMIN = 'BILLABLE_ADMIN',
  PARALEGAL = 'PARALEGAL',
}

/** Possible team member statuses */
export enum TeamMemberStatuses {
  ACTIVE = 'ACTIVE',
  DEACTIVATED = 'DEACTIVATED',
  INVITE_SENT = 'INVITE_SENT',
}

export type TeamMemberSubscriptionInput = {
  accessLevels?: InputMaybe<Array<AccessLevels>>;
  includeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  roles?: InputMaybe<Array<TeamMemberRoles>>;
  statuses?: InputMaybe<Array<TeamMemberStatuses>>;
};

export type TimezoneType = {
  __typename: 'TimezoneType';
  full: Scalars['String']['output'];
  short: Scalars['String']['output'];
};

export type TrackAnalyticEventInput = {
  data: Scalars['JSON']['input'];
  event: Scalars['String']['input'];
  timestamp?: InputMaybe<Scalars['Int']['input']>;
};

export type TrackOpenSharedChatInput = {
  chatId: Scalars['ID']['input'];
  lastSeenMessageId: Scalars['ID']['input'];
};

export type TrackPublicAnalyticEventInput = {
  data: Scalars['JSON']['input'];
  event: PublicAnalyticEvent;
  timestamp?: InputMaybe<Scalars['Int']['input']>;
};

/** Transaction types */
export enum TransactionTypes {
  CREDIT_MEMO = 'CREDIT_MEMO',
  PAYMENT = 'PAYMENT',
  REFUND = 'REFUND',
}

export type TrustFeesAccountConnectType = {
  __typename: 'TrustFeesAccountConnectType';
  clientSecret: Scalars['String']['output'];
};

export type TypingInChatStatusType = {
  __typename: 'TypingInChatStatusType';
  chatId: Scalars['String']['output'];
  participantId: Scalars['String']['output'];
};

/** USA states abbreviations */
export enum UsaStates {
  AK = 'AK',
  AL = 'AL',
  AR = 'AR',
  AS = 'AS',
  AZ = 'AZ',
  CA = 'CA',
  CO = 'CO',
  CT = 'CT',
  DC = 'DC',
  DE = 'DE',
  FL = 'FL',
  FM = 'FM',
  GA = 'GA',
  GU = 'GU',
  HI = 'HI',
  IA = 'IA',
  ID = 'ID',
  IL = 'IL',
  IN = 'IN',
  KS = 'KS',
  KY = 'KY',
  LA = 'LA',
  MA = 'MA',
  MD = 'MD',
  ME = 'ME',
  MH = 'MH',
  MI = 'MI',
  MN = 'MN',
  MO = 'MO',
  MP = 'MP',
  MS = 'MS',
  MT = 'MT',
  NC = 'NC',
  ND = 'ND',
  NE = 'NE',
  NH = 'NH',
  NJ = 'NJ',
  NM = 'NM',
  NV = 'NV',
  NY = 'NY',
  OH = 'OH',
  OK = 'OK',
  OR = 'OR',
  PA = 'PA',
  PR = 'PR',
  PW = 'PW',
  RI = 'RI',
  SC = 'SC',
  SD = 'SD',
  TN = 'TN',
  TX = 'TX',
  UT = 'UT',
  VA = 'VA',
  VI = 'VI',
  VT = 'VT',
  WA = 'WA',
  WI = 'WI',
  WV = 'WV',
  WY = 'WY',
}

export type UpdateAppcloseCalendarEvent = {
  allDay?: InputMaybe<Scalars['Boolean']['input']>;
  attachmentIds?: InputMaybe<Array<Scalars['String']['input']>>;
  calendarId: Scalars['String']['input'];
  from: Scalars['DateTime']['input'];
  id: Scalars['ID']['input'];
  locationName?: InputMaybe<Scalars['String']['input']>;
  memo?: InputMaybe<Scalars['String']['input']>;
  reminder1?: InputMaybe<Scalars['Float']['input']>;
  reminder2?: InputMaybe<Scalars['Float']['input']>;
  repeatPeriod?: InputMaybe<Scalars['Float']['input']>;
  repeatUntil?: InputMaybe<Scalars['String']['input']>;
  shareInfo?: InputMaybe<AppcloseShareInfoInputType>;
  title: Scalars['String']['input'];
  to: Scalars['DateTime']['input'];
};

export type UpdateAppcloseCalendarInput = {
  color: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  shareInfo?: InputMaybe<AppcloseShareInfoInputType>;
  title: Scalars['String']['input'];
};

export type UpdateAppcloseNoteInput = {
  attachmentId?: InputMaybe<Scalars['String']['input']>;
  noteId: Scalars['ID']['input'];
  text?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateClientInput = {
  addresses?: InputMaybe<Array<ClientAddressInput>>;
  email?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<GenderTypes>;
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateContactDataInput = {
  addresses?: InputMaybe<Array<AddressInput>>;
  conflictsCheckCompleted?: InputMaybe<Scalars['Boolean']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  engagementHasBeenSigned?: InputMaybe<Scalars['Boolean']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<GenderTypes>;
  id: Scalars['ID']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
  middleName?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  originatingAttorney?: InputMaybe<Scalars['ID']['input']>;
  phoneNumbers?: InputMaybe<Array<PhoneNumberInput>>;
  taxable?: InputMaybe<Scalars['Boolean']['input']>;
  w9FormSentToClient?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateContactInput = {
  contact?: InputMaybe<UpdateContactDataInput>;
  entity?: InputMaybe<UpdateEntityDataInput>;
};

export type UpdateEntityDataInput = {
  addresses?: InputMaybe<Array<AddressInput>>;
  billingContacts?: InputMaybe<Array<EntityBillingContactsInput>>;
  conflictsCheckCompleted?: InputMaybe<Scalars['Boolean']['input']>;
  engagementHasBeenSigned?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  originatingAttorney?: InputMaybe<Scalars['ID']['input']>;
  phoneNumbers?: InputMaybe<Array<PhoneNumberInput>>;
  taxable?: InputMaybe<Scalars['Boolean']['input']>;
  w9FormSentToClient?: InputMaybe<Scalars['Boolean']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateFilesInput = {
  entityId: Scalars['ID']['input'];
  fileIdsToDelete?: InputMaybe<Array<Scalars['ID']['input']>>;
  newFiles?: InputMaybe<Array<S3FileInput>>;
};

export type UpdateFirmInput = {
  address?: InputMaybe<AddressInput>;
  businessStartedSince?: InputMaybe<Scalars['Date']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  entityType?: InputMaybe<FirmEntityTypes>;
  legalName?: InputMaybe<Scalars['String']['input']>;
  logo?: InputMaybe<PictureInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<PhoneNumberInput>;
  pictureUrl?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<FirmSizes>;
  website?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateInvoiceInput = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  contactId: Scalars['ID']['input'];
  discount: Scalars['Float']['input'];
  dueDate: Scalars['DateTime']['input'];
  id: Scalars['ID']['input'];
  internalMemo?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  percentageDiscount: Scalars['Boolean']['input'];
  scheduleSettings?: InputMaybe<ScheduledPaymentsSettingsInput>;
};

export type UpdateProfileInput = {
  companyInfo?: InputMaybe<InputCompanyInfo>;
  license?: InputMaybe<InputLicense>;
  personalInfo?: InputMaybe<InputPersonalInfo>;
  servicesAndFees?: InputMaybe<InputServicesAndFees>;
};

export type UpdateSignatureTemplateInput = {
  id: Scalars['ID']['input'];
};

export type UpdateSyncOptionsInput = {
  allowNotUniqueName?: InputMaybe<Scalars['Boolean']['input']>;
  breadcrumbs?: InputMaybe<Array<Breadcrumb>>;
  driveId?: InputMaybe<Scalars['ID']['input']>;
  driveName?: InputMaybe<Scalars['String']['input']>;
  fileSyncActive?: InputMaybe<Scalars['Boolean']['input']>;
  folderId?: InputMaybe<Scalars['ID']['input']>;
  folderName?: InputMaybe<Scalars['String']['input']>;
  parentId?: InputMaybe<Scalars['ID']['input']>;
  syncType?: InputMaybe<FileStorageSyncTypes>;
};

export type UpdateTeamMemberInput = {
  firstName?: InputMaybe<Scalars['String']['input']>;
  hourlyRate?: InputMaybe<Scalars['Float']['input']>;
  id: Scalars['ID']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
  picture?: InputMaybe<PictureInput>;
  pictureUrl?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<TeamMemberRoles>;
};

export type UserForBackOfficeType = {
  __typename: 'UserForBackOfficeType';
  appcloseCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  email: Scalars['String']['output'];
  source: AccountSource;
};

export type UserForReviewType = {
  __typename: 'UserForReviewType';
  approvedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  email: Scalars['String']['output'];
  isApproved: Scalars['Boolean']['output'];
  isArchived: Scalars['Boolean']['output'];
  source?: Maybe<AccountSource>;
};

export type UserType = {
  __typename: 'UserType';
  accessLevel?: Maybe<AccessLevels>;
  active?: Maybe<Scalars['Boolean']['output']>;
  additionalEmails?: Maybe<Array<Scalars['String']['output']>>;
  addresses?: Maybe<Array<AddressType>>;
  email: Scalars['String']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<GenderTypes>;
  hourlyRate?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  isOwner: Scalars['Boolean']['output'];
  lastLogin?: Maybe<Scalars['DateTime']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  licenseNumber?: Maybe<Scalars['String']['output']>;
  licensedInStates?: Maybe<Array<UsaStates>>;
  licensedSince?: Maybe<Scalars['Date']['output']>;
  limitedAccessDayQty?: Maybe<Scalars['Int']['output']>;
  middleName?: Maybe<Scalars['String']['output']>;
  onboardStatus: OnboardStatuses;
  phoneNumbers?: Maybe<Array<PhoneNumberType>>;
  picture?: Maybe<PictureType>;
  practiceAreas?: Maybe<Array<Scalars['String']['output']>>;
  privacy: Scalars['Boolean']['output'];
  role: TeamMemberRoles;
  searchable?: Maybe<Scalars['Boolean']['output']>;
  status: TeamMemberStatuses;
  timezone?: Maybe<Scalars['Float']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  website?: Maybe<Scalars['String']['output']>;
};

export type VoidInvoiceType = {
  __typename: 'VoidInvoiceType';
  id: Scalars['ID']['output'];
  voidInvoiceText?: Maybe<Scalars['String']['output']>;
};

export type WebUrlsType = {
  __typename: 'WebUrlsType';
  operatingMAF: Scalars['String']['output'];
  selectPlan: Scalars['String']['output'];
  success: Scalars['String']['output'];
  trustMAF: Scalars['String']['output'];
};

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {},
};
export default result;
